import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Select,
  OutlinedInput
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress, Alert, MenuItem} from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import useGlobalStyles from "../../styles";
import { getCouponSelect, assignCoupon } from "../../utils/api";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MemberCouponForm(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { t } = useTranslation('common');

  const [item, setItem] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [isShowLoading, setIsShowLoading] = useState(false);


  const updateObjectValue = (key, value) => {
    setItem(eve => ({...eve, [key]: value}))
  }

  const fetchInitData = async () => {
    setIsShowLoading(true)
    setItem({
      couponId: '',
      phones: '',
      quantity: 1,
    })
    const couponSelect = await getCouponSelect()
    setCoupons(couponSelect)
    setIsShowLoading(false)
  }

  useEffect(() => {
    fetchInitData()
  },[])

  const submitForm = async (eve) => {
    eve.preventDefault();
    const result = await assignCoupon(item)
    if(result.status) {
      history.push('/member-coupon')
    } else {
      setErrorMessage(result?.data?.message || '')
    }
  }

  return (
    <>
      <PageTitle title={t('member.assignCoupon')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/member-coupon')}
        >
          { t('backToList') }
        </Button>} />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        {errorMessage !== '' ? (<Grid item xs={12} style={{marginBottom: "20px"}}><Alert severity="error">{errorMessage}</Alert></Grid>): (<div></div>)}
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={6}>
            <label>{t('coupon.title')}</label>
            <Select
              displayEmpty
              value={item.couponId || ''}
              onChange={e => updateObjectValue('couponId', e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              <MenuItem value="">{t('selectOne')} {t('coupon.title')}</MenuItem>
              {Object.keys(coupons).map((key) => (
                <MenuItem key={key} value={key}>{coupons[key]}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.phones }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.phones}
              onChange={e => { updateObjectValue('phones', e.target.value) }}
              label={t('member.phones')}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.quantity }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.quantity}
              onChange={e => { updateObjectValue('quantity', e.target.value) }}
              label={t('quantity')}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" sx={{ marginRight: "1rem" }} type="submit">
                {t('save')}
              </Button>
              <Button variant="contained" color="inherit" onClick={() => history.push('/brand-ads')}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </>
  );
}

export default withRouter(MemberCouponForm);