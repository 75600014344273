import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import {
  Grid,
  Box,
} from "@material-ui/core";

// styles
import useGlobalStyles from "../../styles";
import { useHistory, useParams } from "react-router-dom";
import { getPush } from "../../utils/api";
import DetailRow from "../../components/Table/DetailRow";
import DetailRowLink from "../../components/Table/DetailRowLink";
import MultiLanguageDetailRow from '../../components/Table/MultiLanguageDetailRow';
import { useTranslation } from "react-i18next";
import { PUSH_MEMBER_GROUP_TYPE, PUSH_METHODS, PUSH_TYPES } from "../../utils/constant";
import { showDateUTC } from "../../utils/helpers";

function PushDetail(props) {
  var globalClasses = useGlobalStyles();
  const { t } = useTranslation('common');
  const history = useHistory();
  const { id } = useParams();
  const [item, setItem] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    const result = await getPush(id);
    setItem(result);
    setIsShowLoading(false);
  }

  useEffect(() => {
    fetchItem()
  }, [])

  return (
    <>
      <PageTitle title={t('push.detail')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/push')}
        >
          { t('backToList') }
        </Button>}
      />
      <Box style={{marginBottom: "3rem"}}>
        <Grid className={globalClasses.containerDetail}>
          <DetailRow label={t('id')} value={item._id}/>
          {
            Number(item.contentType) === 2 ? (
              <>
               <DetailRowLink label={`${t('news.title')} ${t('title')}`} value={item.news?.title} link={`/news/${item.news?._id}`}/>
              </>
            ) : 
            Number(item.contentType) === 3 ? (
              <>
              <DetailRowLink label={`${t('brandAds.title')} ${t('title')}`} value={item.ads?.name} link={`/brand-ads/${item.ads?._id}`}/>
              </>
            ) : (
              <>
                <MultiLanguageDetailRow label={t('name')} value={item.name}/>
                <MultiLanguageDetailRow label={t('content')} value={item.content}/>
              </>
            )
          }
          <DetailRow label={t('type')} value={item.pushTypeName}/>
          { (Number(item.pushType) !== PUSH_TYPES.instant && Number(item.pushType) !== PUSH_TYPES.specificDateTime) && (
            <>
              <DetailRow label={t('push.periodStart')} value={item?.periodStart ? showDateUTC(item?.periodStart) : ''}/>
              <DetailRow label={t('push.periodEnd')} value={item?.periodEnd ? showDateUTC(item?.periodEnd) : ''}/>
            </>
          ) }
          { Number(item.pushType) === PUSH_TYPES.specificDateTime && (
            <>
            <DetailRow label={t('push.executeDate')} value={item?.executeDate ? showDateUTC(item?.executeDate) : ''}/>
            </>
          )}
          { (Number(item.pushType) !== PUSH_TYPES.instant) && (
            <>
              <DetailRow label={t('push.executeTime')} value={item.executeTime}/>
            </>
          ) }
          { Number(item.pushType) === PUSH_TYPES.weekly && (
            <>
              <DetailRow label={t('push.weekday')} value={item.weekday}/>
            </>
          )}
          { (Number(item.pushType) === PUSH_TYPES.monthly || Number(item.pushType) === PUSH_TYPES.yearly)&& (
            <>
              <DetailRow label={t('push.dayOfMonth')} value={item.day}/>
            </>
          )}
          { Number(item.pushType) === PUSH_TYPES.yearly && (
            <>
              <DetailRow label={t('push.month')} value={item.month}/>
            </>
          )}
          <DetailRow label={t('push.method')} value={item.pushMethodName}/>
          { Number(item.pushMethod) === PUSH_METHODS.someone && (
            <>
              <DetailRow label={t('push.members')} value={item.members.join(', ')}/>
            </>
          )}
          { Number(item.pushMethod) === PUSH_METHODS.conditions && (
            <>
              <DetailRow label={t('gender')} value={item.gender}/>
              <DetailRow label={t('push.dobMonth')} value={item.dobMonth}/>
              <DetailRow label={t('push.tierLevel')} value={item.tierLevel}/>
              <DetailRow label={t('push.joinFrom')} value={item.joinFrom}/>
              <DetailRow label={t('push.joinTo')} value={item.joinTo}/>
              <DetailRow label={t('push.spendingForm')} value={item.spendingForm}/>
              <DetailRow label={t('push.spendingTo')} value={item.spendingTo}/>
            </>
          )}
          { Number(item.pushMethod) === PUSH_METHODS.group && (
            <>
              <DetailRow label={t('push.memberGroupType')} value={item.memberGroupTypeName}/>
              { 
                item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.gender && (
                  <DetailRow label={t('gender')} value={t(item.gender)}/>
                )
              }
              { 
                (item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.ghostCustomer || 
                  item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.newMember ||
                  item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.useCoupon ||
                  item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.scanInvoice) && (
                  <DetailRow label={t('push.month')} value={t('push.monthRange', {month: item.monthRange})}/>
                )
              }
              { 
                item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.endPhoneNumber && (
                  <DetailRow label={t('push.number')} value={item.containPhoneNumbers.join(', ')}/>
                )
              }
              { 
                item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.scanInvoiceInDay && (
                  <DetailRow label={t('push.day')} value={item.dayOfWeekNames.join(', ')}/>
                )
              }
              { 
                item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.scanInvoiceInTimeSlot && (
                  <DetailRow label={t('push.timeSlot')} value={item?.timeSlotName ?? ''}/>
                )
              }
            </>
          )}
          <DetailRow label={t('isDone')} value={item.isDone ? 'Yes' : 'No'}/>
          <DetailRow label={t('isActive')} value={item.isActive ? 'Yes' : 'No'}/>
          <DetailRow label={t('createdAt')} value={showDateUTC(item.createdAt) }/>
          <DetailRow label={t('updatedAt')} value={showDateUTC(item.updatedAt) } isNoneBorder={true}/>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(PushDetail);
