import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TablePagination,
} from "@material-ui/core";
import TablePaginationActions from "../../../components/Table/TablePaginationActions";
import { useTranslation } from "react-i18next";
import { showDateUTC } from "../../../utils/helpers";

const MemberHasTransaction = (props) => {
  const { t } = useTranslation("common");
  const { tableData, page, rowsPerPage, updatePage, total } = props

  const handleChangePage = (event, newPage) => {
    updatePage(newPage + 1);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('id')}</TableCell>
            <TableCell align="left">{t('name')}</TableCell>
            <TableCell align="left">{t('phone')}</TableCell>
            <TableCell align="left">{t('email')}</TableCell>
            <TableCell align="left">{t('member.currentTier')}</TableCell>
            <TableCell align="left">{t('member.currentSpending')}</TableCell>
            <TableCell align="left">{t('member.gCoin')}</TableCell>
            <TableCell align="left">{t('createdAt')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { 
            (Array.isArray(tableData) && tableData.length > 0) && tableData.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="left">{row._id}</TableCell>
                <TableCell align="left">{row.fullName}</TableCell>
                <TableCell align="left">{row.phone}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.currentTierName}</TableCell>
                <TableCell align="left">{row.spending}</TableCell>
                <TableCell align="left">{row.currentGCoins}</TableCell>
                <TableCell align="left">{row.isEnabled ? 'Yes' : 'No'}</TableCell>
                <TableCell align="left">{showDateUTC(row.createdDate)}</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer>
  );
};
export default MemberHasTransaction;