import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TableHead,
  TextField,
  OutlinedInput
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress, Select, MenuItem } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import TablePaginationActions from "../../components/Table/TablePaginationActions"

// styles
import useGlobalStyles from "../../styles";
import { getMemberTransctions, getTransactioTypes, getBrandLocationData, getExportMemberTransactions, deleteFileUserTransaction } from "../../utils/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDatepicker, formatDatepickerSumit, showDateUTC } from "../../utils/helpers";

function MemberTransaction(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { t } = useTranslation('common');
  const defaultPaging = {
    sortName: '',
    sortType: '',
    limitPerPage: 20,
    page: 0,
  }

  const downloadButton = useRef();

  const [pagingData, setPagingData] = useState(defaultPaging);
  const [actionType, setActionType] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [redeemDate, setRedeemDate] = useState(null);
  const [actionTypeData, setActionTypeData] = useState([]);

  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const [locations, setLocations] = useState([]);

  const fetchList = async() => {
    setIsShowLoading(true);
    const newData = redeemDate ? formatDatepickerSumit(redeemDate) : '';
    const data = { ...pagingData,
      page: pagingData.page + 1,
      actionType,
      phone,
      location,
      invoiceNumber,
      redeemDate: newData,
    }
    const result = await getMemberTransctions(data);
    setTotalItems(result?.totalItems ?? 0)
    setItems(result?.itemList ?? 0);
    setIsShowLoading(false);
  }

  const getData = async() => {
    const actionTypes = await getTransactioTypes();
    setActionTypeData(actionTypes);
    const locationData = await getBrandLocationData({})
    setLocations(locationData)
  }

  useEffect(() => {
    getData()
    fetchList();
  }, [pagingData])

  const handleChangePage = (event, newPage) => {
    const data = pagingData
    data.page = newPage
    setPagingData(data);
    fetchList();
  };

  const clearSearchData = () => {
    setPagingData(defaultPaging);
    setActionType('');
    setPhone('');
    setInvoiceNumber('');
    setLocation('');
    setRedeemDate(null);
  }

  const openDetail = (id) => {
    history.push(`/member-transaction/${id}`);
  }

  const submitForm = (eve) => {
    eve.preventDefault();
    const data = pagingData
    data.page = 0
    setPagingData(data);
    fetchList()
  }

    const handleExportCSV = async () => {
        const dataSearch = {
            actionType,
            phone,
            location,
            invoiceNumber,
            redeemDate
        };
        setIsShowLoading(true);
        const response = await getExportMemberTransactions(dataSearch);

        if (response?.url) {
            try {
                const anchorElement = document.createElement("a");
                anchorElement.href = response.url;
                anchorElement.download = "download.csv";
                document.body.appendChild(anchorElement);
                await new Promise(
                    (resolve) => setTimeout(() => {
                        resolve();
                    }, 1000)
                )
                anchorElement.click();
                document.body.removeChild(anchorElement);
                URL.revokeObjectURL(response.url);
            } catch (error) {
                console.error("Error downloading the file:", error);
            }
        } else {
            setErrorMessage("Export SCV has error. Please contact administrator");
        }

        // Call API Delete link file in folder
        const deleteFile = await deleteFileUserTransaction();
        if (deleteFile?.url) {
            URL.revokeObjectURL(response.url);
            URL.revokeObjectURL(response.message);
        }

        setTimeout(() => {
            setIsShowLoading(false);
        }, 200);
    };

  return (
    <>
      <PageTitle title={t('member.memberTransaction')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/member-transaction/add-point')}
        >
          { t('member.addGCoin') }
        </Button>} />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={3}>
            <label>{t('member.actionType')}</label>
            <Select
              displayEmpty
              value={actionType}
              onChange={e => setActionType(e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {actionTypeData.map((item, index) => (
                <MenuItem key={index} value={item.key}>{item.title}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <TextField
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={phone}
              onChange={e => setPhone(e.target.value)}
              label={t('phone')}
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <label>{t('member.shopCode')}</label>
            <Select
              displayEmpty
              value={location}
              onChange={e => setLocation(e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {locations.map((item, index) => (
                <MenuItem key={index} value={item._id}>{item.name}-{item.code}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <TextField
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={invoiceNumber}
              onChange={e => setInvoiceNumber(e.target.value)}
              label={t('member.invoiceNumber')}
              type="number"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={3}>
            <DatePicker
              label={t('createdAt')}
              value={redeemDate}
              views={["year", "month", "day"]}
              onChange={e => setRedeemDate(e)}
              renderInput={(params) => <TextField {...params} fullWidth inputProps={
                { 
                  ...params.inputsProps, 
                  value: redeemDate ? formatDatepicker(redeemDate) : '',
                }
              }/>}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container justifyContent="flex-end">
                <a
                    ref={downloadButton}
                    download=""
                    style={{ display: "hidden" }}
                ></a>
                <Button
                    color="secondary"
                    variant="contained"
                    sx={{ minWidth: "90px", marginRight: "10px" }}
                    onClick={handleExportCSV}
                >
                {t('csv')}
                </Button>
                <Button variant="contained" sx={{ marginRight: "1rem" }} type="submit">
                    {t('search')}
                </Button>
                <Button variant="contained" color="inherit" onClick={() => clearSearchData()}>
                    {t('clear')}
                </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('name')}
                  </TableCell>
                  <TableCell>
                    {t('phone')}
                  </TableCell>
                  <TableCell>
                    {t('member.actionType')}
                  </TableCell>
                  <TableCell>
                    {t('member.invoiceNumber')}
                  </TableCell>
                  <TableCell>
                    {t('member.paymentAmount')}
                  </TableCell>
                  <TableCell align="center">
                    {t('member.gCoin')}
                  </TableCell>
                  <TableCell>
                    {t('createdAt')}
                  </TableCell>
                  <TableCell >
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  items?.length > 0 ? 
                    items.map((row) => {
                      return (
                      <TableRow key={row._id}>
                        <TableCell component="th" scope="row">
                          {row.member?.fullName}
                        </TableCell>
                        <TableCell>
                          {row.member?.phone}
                        </TableCell>
                        <TableCell>
                          {row.actionName}
                        </TableCell>
                        <TableCell>
                          {row.invoice?.invoiceNumber || ''}
                        </TableCell>
                        <TableCell>
                          {row.invoice?.spendingAmount || ''}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="center">
                          {`${row.operator} ${row.gCoinAmount}`}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          { showDateUTC(row.createdAt) }
                        </TableCell>
                        <TableCell style={{ width: 220 }} align="left">
                          <Button variant="contained" size="small" color="info" sx={{ marginRight: "1rem" }} onClick={() => openDetail(row._id)}>
                            {t('view')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}) : (
                    <TableRow >
                      <TableCell colSpan={8} >{ t('message.notAnyRecord') }</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={3}
                    count={totalItems}
                    rowsPerPage={pagingData.limitPerPage}
                    page={pagingData.page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(MemberTransaction);