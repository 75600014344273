import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import {
  Grid,
  Box,
} from "@material-ui/core";

// styles
import useGlobalStyles from "../../styles";
import { useHistory, useParams } from "react-router-dom";
import { getOldMember } from "../../utils/api";
import DetailRow from "../../components/Table/DetailRow";
import { useTranslation } from "react-i18next";

function OldMemberDetail(props) {
  var globalClasses = useGlobalStyles();
  const { t } = useTranslation('common');
  const history = useHistory();
  const { id } = useParams();
  const [item, setItem] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    const result = await getOldMember(id);
    setItem(result);
    setIsShowLoading(false);
  }

  useEffect(() => {
    fetchItem()
  }, [])

  return (
    <>
      <PageTitle title={t('staff.detail')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/old-member')}
        >
          { t('backToList') }
        </Button>}
      />
      <Box style={{marginBottom: "3rem"}}>
        <Grid className={globalClasses.containerDetail}>
          <DetailRow label={t('id')} value={item._id}/>
          <DetailRow label={t('name')} value={item?.name1}/>
          <DetailRow label={`${t('name')} 2`} value={item?.name2}/>
          <DetailRow label={t('email')} value={item?.email ?? ''}/>
          <DetailRow label={t('phone')} value={item.mobile}/>
          <DetailRow label={t('code')} value={item.memberCode}/>
          <DetailRow label={t('memberTypeCode')} value={item.memberTypeCode}/>
          <DetailRow label={t('title')} value={item?.title}/>
          <DetailRow label={t('birthMonth')} value={item?.monthOfBirth ?? ''}/>
          <DetailRow label={t('birthday')} value={item?.dayOfBirth ?? ''}/>
          <DetailRow label={t('sex')} value={item?.sex ?? ''}/>
          <DetailRow label={t('issuedDate')} value={item?.issuedDate ?? ''}/>
          <DetailRow label={t('issued')} value={item?.issued ?? ''}/>
          <DetailRow label={t('point')} value={item?.point ?? ''}/>
          <DetailRow label={t('expiryDate')} value={item?.expiryDate ?? ''}/>
          <DetailRow label={t('isActiveOldAccount')} value={item.isActived ? 'Yes' : 'No'}/>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(OldMemberDetail);
