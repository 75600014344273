import { DATE_ONLY_FORMAT, DATE_TIME_FORMAT, HONGKONG_TIMEZONE_NAME, MULTIPLE_LANGUAGES } from "./constant";
import cloneDeep from 'lodash/cloneDeep'
import { toast } from 'react-toastify';
import moment from "moment-timezone";

export function convertDataToViewMultilanguage (data, keys, newKey) {
    const result = cloneDeep(data)
    let languages = {}
    MULTIPLE_LANGUAGES.forEach(lang => {
        let objLang = {}
        keys.forEach(key => {
            objLang = {...objLang, [key]: data?.[key]?.[lang] || ''}
        });
        languages = {...languages, [lang]: objLang}
    })
    keys.forEach(key => {
        delete result[key]
    });

    result[newKey] = languages
    return result
}

export function convertViewToDataMultilanguage (data, keys, newKey, dataPostkeys) {
    const result = cloneDeep(data)
    keys.forEach(key => {
        let keyData = {}
        MULTIPLE_LANGUAGES.forEach(lang => {
            keyData = {...keyData, [lang]: data[newKey]?.[lang]?.[key] || ''}
        });
        result[key] = keyData
    })
    delete result[newKey]

    for(const keyData in result) {
        if(!dataPostkeys.includes(keyData)){
            delete result[keyData]
        }
    }
    return result
}

export function convertViewToFormData (data, keys, newKey, dataPostkeys, filekeys, arrayKeys = [], arrayObject = []) {
    const formData = new FormData();
    keys.forEach(key => {
        MULTIPLE_LANGUAGES.forEach(lang => {
            formData.append(`${key}[${lang}]`, data[newKey]?.[lang]?.[key] || '');
        });
        delete data[key]
    })

    for(const keyData in data) {
        if(dataPostkeys.includes(keyData) && data[keyData]){
            formData.append(keyData, data[keyData]);
        }
    }

    arrayKeys.forEach(key => {
        if(data?.[key]){
            data[key].forEach(item => {
                formData.append(`${key}[]`, item);
            })
        }
    })

    arrayObject.forEach(key => {
        if(data?.[key]){
            for(var index in data[key]){
                formData.append(`${key}[${index}]`, data[key][index]);
            }
        }
    })

    filekeys.forEach(key => {
        if(data[key]?.name){
            formData.append(key, data[key]);
        }
    });
    return formData
}

export function setDefaultObject (allKeys, keys, keyLangauge) {
    const data = {}
    allKeys.forEach(key => {
        if(!keys.includes(key)){
            data[key] = ''
        }
    })
    let languages = {}
    MULTIPLE_LANGUAGES.forEach(lang => {
        let objLang = {}
        keys.forEach(key => {
            objLang = {...objLang, [key]: data?.[key]?.[lang] || ''}
        });
        languages = {...languages, [lang]: objLang}
    })
    if (keyLangauge) {
        data[keyLangauge] = languages
    }
    return data
}

export function validateLanguageObject(data, requiredKeys){
    let indexTabLang = 0;
    let result = null;
    MULTIPLE_LANGUAGES.forEach(lang => {
        for(const key in data[lang]){
            if(requiredKeys.includes(key) && String(data[lang][key]).trim() === '') {
                result = {
                    indexTabLang,
                    key
                }
                return;
            }
        }
        indexTabLang++;
        if (result) return;
    })
    return result
}

export function isEmpty (value) {
    if (value === undefined || value === null) {
        return true
    }
    if (typeof value === 'string') {
        return value === ''
    }
    if (Array.isArray(value)) {
        return value.length === 0
    }
    if (value instanceof Date) {
        return value.getTime() === 0
    }
    if (typeof value === 'object') {
        return value && Object.keys(value).length === 0
    }
}

export function isMultiLanguageFormValid(value){
    return value !== "";
}

export function emailValidator(value){
    const regex = new RegExp(/^\S+@\S+$/i);
    const isValid = regex.test(value)
    return isValid
}

export function showErrorToast(message) {
    toast.error(message);
}

export function showSuccessToast(message) {
    toast.success(message);
}

export function showDateUTC(date) {
    return moment.parseZone(date).tz(HONGKONG_TIMEZONE_NAME).format('YYYY/MM/DD HH:mm:ss')
}

export function showDateHK(date) {
    return moment.parseZone(date).format('YYYY/MM/DD HH:mm:ss')
}

export function showOnlyDateUTC(date) {
    return moment.parseZone(date).tz(HONGKONG_TIMEZONE_NAME).format('YYYY/MM/DD')
}

export function formatResponseDate(date) {
    return moment.parseZone(date).tz(HONGKONG_TIMEZONE_NAME).format(DATE_ONLY_FORMAT)
}

export function formatDatepicker(date) {
    return moment(date).format(DATE_ONLY_FORMAT)
}

export function formatDatepickerSumit(date) {
    return moment(date).format(DATE_ONLY_FORMAT)
}

export function formatDatetimePicker(date) {
    return moment(date).format(DATE_TIME_FORMAT)
}

export function formatDatetimePickerSubmit(date) {
    return moment(date).utc().format(DATE_TIME_FORMAT)
}