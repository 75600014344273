import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Button, Backdrop, CircularProgress, Alert } from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
// styles
import useGlobalStyles from "../../styles";
import {
  createBrandGroup,
  updateBrandGroup,
  getBrandGroup,
} from "../../utils/api";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  convertDataToViewMultilanguage,
  convertViewToDataMultilanguage,
  setDefaultObject,
  showErrorToast,
  validateLanguageObject,
} from "../../utils/helpers";
import MultiLanguageTabs from "../../components/Common/MultiLanguageTabs";

function BrandGroupForm(props) {
  const globalStyles = useGlobalStyles();
  
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation("common");
  const keys = ["name", "description"];
  const dataPostkeys = ["name", "description", "code", "screenOrder"];
  const keyLangauge = "brandGroupLang";

  const [item, setItem] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    if (id) {
      const itemData = await getBrandGroup(id);
      const formatDataLanguage = convertDataToViewMultilanguage(
        itemData,
        keys,
        keyLangauge
      );
      setItem(formatDataLanguage);
    } else {
      const defaultData = setDefaultObject(dataPostkeys, keys, keyLangauge);
      setItem(defaultData);
    }
    setIsShowLoading(false);
  };

  const updateObjectValue = (key, value) => {
    setItem((eve) => ({ ...eve, [key]: value }));
  };

  useEffect(() => {
    fetchItem();
  }, []);

  const languageRef = useRef()
  
  const submitForm = async (eve) => {
    eve.preventDefault();

    const resultValidate = validateLanguageObject(item[keyLangauge], keys)
    if (resultValidate){
      languageRef?.current?.handleActiveTab(resultValidate.indexTabLang)
      showErrorToast(`${t('requiredField', {field: resultValidate.key})}`)
      return;
    }

    const data = convertViewToDataMultilanguage(
      item,
      keys,
      keyLangauge,
      dataPostkeys
    );
    let result = {};
    if (id) {
      result = await updateBrandGroup(id, data);
    } else {
      result = await createBrandGroup(data);
    }
    if (result.status) {
      history.push("/brand-group");
    } else {
      setErrorMessage(result?.data?.message || "");
    }
  };

  return (
    <>
      <PageTitle
        title={t("brandGroup.form")}
        button={
          <Button
            variant="contained"
            size="medium"
            color="inherit"
            onClick={() => history.push("/brand-group")}
          >
            {t("backToList")}
          </Button>
        }
      />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        {errorMessage !== "" ? (
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        ) : (
          <div></div>
        )}
        <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.code }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.code}
              required
              onChange={(e) => {
                updateObjectValue("code", e.target.value);
              }}
              label={t("code")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12}>
            {item[keyLangauge] ? (
              <MultiLanguageTabs
                ref={languageRef}
                data={item[keyLangauge]}
                updateObjectValue={(data) =>
                  updateObjectValue(keyLangauge, data)
                }
                keys={keys}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.screenOrder }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.screenOrder}
              onChange={(e) => {
                updateObjectValue("screenOrder", e.target.value);
              }}
              label={t("screenOrder")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ marginRight: "1rem" }}
                type="submit"
              >
                {t("save")}
              </Button>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => history.push("/brand-group")}
              >
                {t("cancel")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </>
  );
}

export default withRouter(BrandGroupForm);
