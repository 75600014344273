import axios from 'axios';
import { useLayoutEffect } from 'react'
import { useUserDispatch, signOut } from '../../context/UserContext'
import { useHistory } from "react-router-dom";
import i18next from "i18next";

const instance = axios.create({
    baseURL:  process.env.REACT_APP_API_BASE_URL
})

instance.interceptors.request.use(config => {
    const token = localStorage.getItem('id_token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.headers['Accept-Language'] = i18next.language;
    return config;
})

const AxiosInterceptor = ({ children }) => {
    const history = useHistory();
    const userDispatch = useUserDispatch()

    useLayoutEffect(() => {
        const resInterceptor = response => {
            return response;
        }

        const errInterceptor = error => {
            if (error.response.status === 401 || error.response.status === 403) {
                return signOut(userDispatch, history);
            }
            if (error.response.status === 404) {
                history.push('/error');
            }

            return Promise.reject(error);
        }

        const interceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);
        
        return () => instance.interceptors.response.eject(interceptor);
    }, [])

    return children;
}

export default instance;
export { AxiosInterceptor }