import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    exportWrapper: {
        "& a": {
            textDecoration: 'none',
            cursor: 'pointer',
            color: "white"
        }
    },
    
}));
