export const reportRequestConfig = {
  1: "admin-report/gender",
  2: "admin-report/birthday",
  3: "admin-report/high-spending",
  4: "admin-report/high-visitor",
  5: "admin-report/have-transaction",
  6: "admin-report/not-have-transaction",
  7: "admin-report/have-coupon",
  8: "admin-report/not-have-coupon",
  9: "admin-report/member-register",
  10: "admin-report/sleeping-member",
}

export const DEFAULT_OPTIONS_REPORTS = {
  1: "Customer's gender report",
  2: "Customer's birthday report",
  3: "Top 100 high spending report",
  4: "Top 100 no of visits report",
  5: "Member has transaction",
  6: "Member does NOT have transaction",
  7: "Member has coupon",
  8: "Member does NOT have coupon",
  9: "Register Member",
  10: "Sleeping Member",
};
