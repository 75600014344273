import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  Select,
  OutlinedInput
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress, Alert, MenuItem, FormControlLabel, Checkbox} from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import useGlobalStyles from "../../styles";
import { getTierData, getWeekday, getPushTypes, getPushMethods, getPush, updatePush, createPush, getAllNewsList, getPushContentTypes, getAllBrandAdsList } from "../../utils/api";
import MultiLanguageTabs from "../../components/Common/MultiLanguageTabs";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { convertDataToViewMultilanguage, convertViewToFormData, formatDatepicker, formatDatepickerSumit, formatResponseDate, setDefaultObject, showErrorToast, validateLanguageObject } from "../../utils/helpers";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputMaskTime from '../../components/Common/InputMaskTime'
import { GENDER, MULTIPLE_LANGUAGES, PUSH_METHODS, PUSH_TYPES } from "../../utils/constant";

function PushForm(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation('common');
  const keys = ["name", "content"];
  const dataKeys = ['pushMethod', 'gender', 'dobMonth', 'tierLevel', 'joinFrom', 'joinTo',
    'spendingForm', 'spendingTo', 'members', 'name', 'content', 'pushType', 'contentType', 'newsId', 'adsId',
    'periodStart', 'periodEnd', 'executeDate', 'executeTime', 'weekday', 'day', 'month', 'isActive'];
  const keyLangauge = "pushLang";

  const [item, setItem] = useState({});
  const [types, setTypes] = useState({});
  const [methods, setMethods] = useState({});
  const [contentTypes, setContentTypes] = useState({});
  const [tiers, setTiers] = useState({});
  const [news, setNews] = useState([]);
  const [ads, setAds] = useState([]);
  const [weekdays, setWeekdays] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const [isShowLoading, setIsShowLoading] = useState(false);

  const languageRef = useRef();

  const fetchItem = async () => {
    setIsShowLoading(true);
    if(id){
      const itemData = await getPush(id);
      if(itemData){
        const formatDataLanguage = convertDataToViewMultilanguage(itemData, keys, keyLangauge)
        if (item?.periodStart) {
          item.periodStart = formatResponseDate(item.periodStart);
        }
        if (item?.periodEnd) {
          item.periodEnd = formatResponseDate(item.periodEnd);
        }
        if (item?.executeDate) {
          item.executeDate = formatResponseDate(item.executeDate);
        }
        if (item?.joinFrom) {
          item.joinFrom = formatResponseDate(item.joinFrom);
        }
        if (item?.joinTo) {
          item.joinTo = formatResponseDate(item.joinTo);
        }
        setItem(formatDataLanguage);
      }
    } else {
      const defaultData = setDefaultObject(dataKeys, keys, keyLangauge);
      defaultData.joinFrom = null
      defaultData.joinTo = null
      defaultData.periodStart = null
      defaultData.periodEnd = null
      defaultData.executeDate = null
      setItem(defaultData)
    }
    setIsShowLoading(false);
  }

  const updateObjectValue = (key, value) => {
    setItem(eve => ({...eve, [key]: value}))
  }

  const fetchInitData = async () => {
    const dataTypes = await getPushTypes()
    setTypes(dataTypes ?? {})
    const dataMethods = await getPushMethods()
    setMethods(dataMethods ?? {})
    const dataContentTypes = await getPushContentTypes()
    setContentTypes(dataContentTypes ?? {})
    const tierData = await getTierData()
    setTiers(tierData ?? {})
    const weekdayData = await getWeekday()
    setWeekdays(weekdayData ?? {})
    const newsList = await getAllNewsList()
    setNews(newsList ?? [])
    const adsList = await getAllBrandAdsList()
    setAds(adsList ?? [])
  }

  useEffect(() => {
    fetchItem()
    fetchInitData()
  },[])

  const submitForm = async (eve) => {
    eve.preventDefault();
    let result = {}
    if (Number(item.contentType) === 1) {
      const resultValidate = validateLanguageObject(item[keyLangauge], keys)
      if (resultValidate){
        languageRef?.current?.handleActiveTab(resultValidate.indexTabLang)
        showErrorToast(`${t('requiredField', {field: resultValidate.key})}`)
        return;
      }
    } else {
      MULTIPLE_LANGUAGES.forEach(lang => {
        for(const key in item[keyLangauge][lang]){
          item[keyLangauge][lang][key] = ''
        }
      })
    }

    const cloneItem = {...item, 
      periodStart: item?.periodStart ? formatDatepickerSumit(item.periodStart) : null,
      periodEnd: item?.periodEnd ?  formatDatepickerSumit(item.periodEnd) : null,
      executeDate: item?.executeDate ?  formatDatepickerSumit(item.executeDate) : null,
      joinFrom: item?.joinFrom ?  formatDatepickerSumit(item.joinFrom) : null,
      joinTo: item?.joinTo ?  formatDatepickerSumit(item.joinTo) : null,
    }
    
    const data = convertViewToFormData(cloneItem, keys, keyLangauge, dataKeys, [], [], [])
    if (id) {
      result = await updatePush(id, data)
    } else {
      result = await createPush(data)
    }
    if(result.status) {
      history.push('/push')
    } else {
      setErrorMessage(result?.data?.message || '')
    }
  }

  return (
    <>
      <PageTitle title={t('push.form')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/push')}
        >
          { t('backToList') }
        </Button>} />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        {errorMessage !== '' ? (<Grid item xs={12} style={{marginBottom: "20px"}}><Alert severity="error">{errorMessage}</Alert></Grid>): (<div></div>)}
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <label>{t('type')}</label>
                <Select
                  required
                  displayEmpty
                  value={item.pushType || ''}
                  onChange={e => updateObjectValue('pushType', e.target.value)}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      classes={{
                        notchedOutline: globalStyles.mainChartSelectRoot,
                        input: globalStyles.mainChartSelect,
                      }}
                    />
                  }
                  fullWidth
                >
                  <MenuItem value="">{t('selectOne')} {t('type')}</MenuItem>
                  {Object.entries(types ?? {}).map((item) => (
                    <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}></Grid>
              { item.pushType !== '' 
                && (Number(item.pushType) !== PUSH_TYPES.instant && Number(item.pushType) !== PUSH_TYPES.specificDateTime) 
                && (
                <>
                  <Grid item xs={6}>
                    <DatePicker
                      label={t('push.periodStart')}
                      value={item.periodStart}
                      views={["year", "month", "day"]}
                      onChange={e => updateObjectValue('periodStart', e)}
                      renderInput={(params) => <TextField {...params} fullWidth inputProps={
                        { 
                          ...params.inputsProps, 
                          value: item.periodStart ? formatDatepicker(item.periodStart): '',
                        }
                      }
                    />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label={t('push.periodEnd')}
                      value={item.periodEnd}
                      views={["year", "month", "day"]}
                      onChange={e => updateObjectValue('periodEnd', e)}
                      renderInput={(params) => <TextField {...params} fullWidth inputProps={
                        { 
                          ...params.inputsProps, 
                          value: item.periodEnd ? formatDatepicker(item.periodEnd) : '',
                        }
                      }
                    />}
                    />
                  </Grid>
                </>
              ) }
              { Number(item.pushType) === PUSH_TYPES.specificDateTime
                && (
                <>
                  <Grid item xs={6}>
                    <DatePicker
                      label={t('push.executeDate')}
                      value={item.executeDate}
                      views={["year", "month", "day"]}
                      onChange={e => updateObjectValue('executeDate', e)}
                      renderInput={(params) => <TextField {...params} fullWidth inputProps={
                        { 
                          ...params.inputsProps, 
                          value: item.executeDate ? formatDatepicker(item.executeDate) : '',
                        }
                      }
                    />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                  </Grid>
                </>
              ) }
              { item.pushType !== '' 
                && (Number(item.pushType) !== PUSH_TYPES.instant) 
                && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: !!item.executeTime }}
                      InputProps={{
                        classes: {
                          underline: globalStyles.textFieldUnderline,
                          input: globalStyles.textField,
                        },
                        inputComponent: InputMaskTime,
                      }}
                      name="executeTime"
                      value={item.executeTime}
                      onChange={e => { updateObjectValue('executeTime', e.target.value) }}
                      label={t('push.executeTime')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                  </Grid>
                </>
              ) }
              { Number(item.pushType) === PUSH_TYPES.weekly && (
                <>
                  <Grid item xs={6}>
                    <label>{t('push.weekday')}</label>
                    <Select
                      displayEmpty
                      value={item.weekday || ''}
                      onChange={e => updateObjectValue('weekday', e.target.value)}
                      input={
                        <OutlinedInput
                          labelWidth={0}
                          classes={{
                            notchedOutline: globalStyles.mainChartSelectRoot,
                            input: globalStyles.mainChartSelect,
                          }}
                        />
                      }
                      fullWidth
                    >
                      <MenuItem value="">{t('selectOne')} {t('push.weekday')}</MenuItem>
                      {Object.entries(weekdays ?? {}).map((item, key) => (
                        <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </>
              )}
              { (Number(item.pushType) === PUSH_TYPES.monthly || Number(item.pushType) === PUSH_TYPES.yearly)&& (
                <>
                  <Grid item xs={6}>
                    <label>{t('push.dayOfMonth')}</label>
                    <Select
                      displayEmpty
                      value={item.day || ''}
                      onChange={e => updateObjectValue('day', e.target.value)}
                      input={
                        <OutlinedInput
                          labelWidth={0}
                          classes={{
                            notchedOutline: globalStyles.mainChartSelectRoot,
                            input: globalStyles.mainChartSelect,
                          }}
                        />
                      }
                      fullWidth
                    >
                      <MenuItem value="">{t('selectOne')} {t('push.dayOfMonth')}</MenuItem>
                      {Array.from(Array(31), (element, index)=> {
                        return <MenuItem key={index} value={index + 1}>{(index + 1).toString().padStart(2, '0')}</MenuItem>
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </>
              )}
              { Number(item.pushType) === PUSH_TYPES.yearly && (
                <>
                  <Grid item xs={6}>
                    <label>{t('push.month')}</label>
                    <Select
                      displayEmpty
                      value={item.month || ''}
                      onChange={e => updateObjectValue('month', e.target.value)}
                      input={
                        <OutlinedInput
                          labelWidth={0}
                          classes={{
                            notchedOutline: globalStyles.mainChartSelectRoot,
                            input: globalStyles.mainChartSelect,
                          }}
                        />
                      }
                      fullWidth
                    >
                      <MenuItem value="">{t('selectOne')} {t('push.month')}</MenuItem>
                      {Array.from(Array(12), (element, index)=> {
                        return <MenuItem key={index} value={index + 1}>{(index + 1).toString().padStart(2, '0')}</MenuItem>
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ borderTop: "1px solid #999" }}></div>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <label>{t('push.method')}</label>
                <Select
                  required
                  displayEmpty
                  value={item.pushMethod || ''}
                  onChange={e => updateObjectValue('pushMethod', e.target.value)}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      classes={{
                        notchedOutline: globalStyles.mainChartSelectRoot,
                        input: globalStyles.mainChartSelect,
                      }}
                    />
                  }
                  fullWidth
                >
                  <MenuItem value="">{t('selectOne')} {t('push.method')}</MenuItem>
                  {Object.entries(methods ?? {}).map((item) => (
                    <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}></Grid>
              { Number(item.pushMethod) === PUSH_METHODS.someone && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      required
                      autoComplete='off'
                      InputLabelProps={{ shrink: !!item.members }}
                      InputProps={{
                        classes: {
                          underline: globalStyles.textFieldUnderline,
                          input: globalStyles.textField,
                        },
                      }}
                      value={item.members}
                      onChange={e => { updateObjectValue('members', e.target.value) }}
                      label={`${t('push.members')}: You can input many Phone Numbers that are separated by ","`}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
              { Number(item.pushMethod) === PUSH_METHODS.conditions && (
                <>
                  <Grid item xs={4}>
                    <label>{t('gender')}</label>
                    <Select
                      displayEmpty
                      value={item.gender || ''}
                      onChange={e => updateObjectValue('gender', e.target.value)}
                      input={
                        <OutlinedInput
                          labelWidth={0}
                          classes={{
                            notchedOutline: globalStyles.mainChartSelectRoot,
                            input: globalStyles.mainChartSelect,
                          }}
                        />
                      }
                      fullWidth
                    >
                      <MenuItem value="">{t('selectOne')} {t('gender')}</MenuItem>
                      {Object.keys(GENDER ?? {}).map((item) => (
                        <MenuItem key={item} value={item}>{t(item)}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <label>{t('push.dobMonth')}</label>
                    <Select
                      displayEmpty
                      value={item.dobMonth || ''}
                      onChange={e => updateObjectValue('dobMonth', e.target.value)}
                      input={
                        <OutlinedInput
                          labelWidth={0}
                          classes={{
                            notchedOutline: globalStyles.mainChartSelectRoot,
                            input: globalStyles.mainChartSelect,
                          }}
                        />
                      }
                      fullWidth
                    >
                      <MenuItem value="">{t('selectOne')} {t('push.dobMonth')}</MenuItem>
                      {Array.from(Array(12), (element, index)=> {
                        return <MenuItem key={index} value={index + 1}>{(index + 1).toString().padStart(2, '0')}</MenuItem>
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <label>{t('push.tierLevel')}</label>
                    <Select
                        displayEmpty
                        value={item.tierLevel || ''}
                        onChange={e => updateObjectValue('tierLevel', e.target.value)}
                        input={
                          <OutlinedInput
                            labelWidth={0}
                            classes={{
                              notchedOutline: globalStyles.mainChartSelectRoot,
                              input: globalStyles.mainChartSelect,
                            }}
                          />
                        }
                        fullWidth
                      >
                        <MenuItem value="">{t('selectOne')} {t('pointScheme.tier')}</MenuItem>
                        {Object.entries(tiers ?? {}).map((item, key) => (
                          <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                        ))}
                      </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label={t('push.joinFrom')}
                      value={item.joinFrom}
                      views={["year", "month", "day"]}
                      onChange={e => updateObjectValue('joinFrom', e)}
                      renderInput={(params) => <TextField {...params} fullWidth inputProps={
                        { 
                          ...params.inputsProps, 
                          value: item.joinFrom ? formatDatepicker(item.joinFrom) : '',
                        }
                      }
                    />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label={t('push.joinTo')}
                      value={item.joinTo}
                      views={["year", "month", "day"]}
                      onChange={e => updateObjectValue('joinTo', e)}
                      renderInput={(params) => <TextField {...params} fullWidth inputProps={
                        { 
                          ...params.inputsProps, 
                          value: item.joinTo ? formatDatepicker(item.joinTo) : '',
                        }
                      }
                    />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="number"
                      InputLabelProps={{ shrink: !!item.spendingForm }}
                      InputProps={{
                        classes: {
                          underline: globalStyles.textFieldUnderline,
                          input: globalStyles.textField,
                        },
                      }}
                      value={item.spendingForm}
                      onChange={e => { updateObjectValue('spendingForm', e.target.value) }}
                      label={t('push.spendingForm')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{ shrink: !!item.spendingTo }}
                      InputProps={{
                        classes: {
                          underline: globalStyles.textFieldUnderline,
                          input: globalStyles.textField,
                        },
                      }}
                      value={item.spendingTo}
                      onChange={e => { updateObjectValue('spendingTo', e.target.value) }}
                      label={t('push.spendingTo')}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ borderTop: "1px solid #999" }}></div>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <label>{t('push.contentType')}</label>
                <Select
                  required
                  displayEmpty
                  value={item.contentType || ''}
                  onChange={e => updateObjectValue('contentType', e.target.value)}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      classes={{
                        notchedOutline: globalStyles.mainChartSelectRoot,
                        input: globalStyles.mainChartSelect,
                      }}
                    />
                  }
                  fullWidth
                >
                  <MenuItem value="">{t('selectOne')} {t('push.contentType')}</MenuItem>
                  {Object.entries(contentTypes ?? {}).map((item) => (
                    <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                  ))}
                </Select>
              </Grid>
              {
                Number(item.contentType) === 2 && (
                  <Grid item xs={8} >
                    <label>{t('news.title')}</label>
                    <Select
                      required
                      displayEmpty
                      value={item.newsId || ''}
                      onChange={e => updateObjectValue('newsId', e.target.value) }
                      input={
                        <OutlinedInput
                          labelWidth={0}
                          classes={{
                            notchedOutline: globalStyles.mainChartSelectRoot,
                            input: globalStyles.mainChartSelect,
                          }}
                        />
                      }
                      fullWidth
                    >
                      <MenuItem value="">{t('selectOne')} {t('news.title')}</MenuItem>
                      {news.map((item) => (
                        <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )
              }
              {
                Number(item.contentType) === 3 && (
                  <Grid item xs={8} >
                    <label>{t('brandAds.title')}</label>
                    <Select
                      required
                      displayEmpty
                      value={item.adsId || ''}
                      onChange={e => updateObjectValue('adsId', e.target.value) }
                      input={
                        <OutlinedInput
                          labelWidth={0}
                          classes={{
                            notchedOutline: globalStyles.mainChartSelectRoot,
                            input: globalStyles.mainChartSelect,
                          }}
                        />
                      }
                      fullWidth
                    >
                      <MenuItem value="">{t('selectOne')} {t('brandAds.title')}</MenuItem>
                      {ads.map((item) => (
                        <MenuItem key={item._id} value={item._id}>{item.brandName} - {item.name}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )
              }
            </Grid>
          </Grid>
          {
            Number(item.contentType) === 1 && (
              <Grid item xs={12}>
                { 
                  item[keyLangauge] ? 
                  (<MultiLanguageTabs data={item[keyLangauge]} ref={languageRef} updateObjectValue={(data) => updateObjectValue(keyLangauge, data)} keys={keys}/>) : 
                  (<></>) 
                }
              </Grid>
            )
          }
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormControlLabel control={<Checkbox 
                    checked={item.isActive || false}
                    onChange={e => { updateObjectValue('isActive', e.target.checked) }} 
                  />} label={t('IsActive')} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" sx={{ marginRight: "1rem" }} type="submit">
                {t('save')}
              </Button>
              <Button variant="contained" color="inherit" onClick={() => history.push('/push')}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </>
  );
}

export default withRouter(PushForm);