import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TableHead,
  Select,
  OutlinedInput
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress, MenuItem } from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import StatusLabel from "../../components/Table/StatusLabel";
import TablePaginationActions from "../../components/Table/TablePaginationActions"

// styles
import useGlobalStyles from "../../styles";
import { getBrandLocationData, getPointSchemes, getPointSchemeTypes } from "../../utils/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDatetimePicker } from "../../utils/helpers";

function PointScheme(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { t } = useTranslation('common');
  const defaultPaging = {
    limitPerPage: 20,
    page: 0,
  }

  const [pagingData, setPagingData] = useState(defaultPaging);
  const [typeId, setTypeId] = useState('');
  const [brandId, setBrandId] = useState('');
  const [shopId, setShopId] = useState('');

  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const [types, setTypes] = useState({});
  const [brandLocations, setBrandLocations] = useState([]);

  const fetchList = async() => {
    setIsShowLoading(true);
    const data = { ...pagingData,
      page: pagingData.page + 1,
      typeId,
      shopId: shopId
    }
    
    const result = await getPointSchemes(data);
    setTotalItems(result.totalItems ?? 0)
    setItems(result.itemList);
    setIsShowLoading(false);
  }

  const getDatas = async () => {
    const data = await getPointSchemeTypes({});
    setTypes(data)
    const brandData = await getBrandLocationData({});
    setBrandLocations(brandData ?? [])
  }

  const getShops = async () => {
    if(brandId){
      const shopData = await getBrandLocationData({brandId: brandId})
      setBrandLocations(shopData)
    } else {
      setBrandLocations([])
    }
  }

  useEffect(() => {
    fetchList();
  }, [pagingData])

  useEffect(() => {
    getDatas();
  }, [])

  useEffect(() => {
    getShops();
  }, [brandId])

  const handleChangePage = (event, newPage) => {
    const data = pagingData
    data.page = newPage
    setPagingData(data);
    fetchList();
  };

  const clearSearchData = () => {
    setPagingData(defaultPaging);
    setTypeId('');
    setBrandId('');
    setShopId('');
  }

  const openDetail = (id) => {
    history.push(`/point-scheme/${id}`);
  }

  const submitForm = (eve) => {
    eve.preventDefault();
    const data = pagingData
    data.page = 0
    setPagingData(data);
    fetchList()
  }

  const openEdit = (id) => {
    history.push(`/point-scheme/${id}/edit`);
  }
  return (
    <>
      <PageTitle title={t('pointScheme.title')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/point-scheme/add')}
        >
          { t('create') }
        </Button>}/>
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={3}>
            <label>{t('type')}</label>
            <Select
              displayEmpty
              value={typeId}
              onChange={e => setTypeId(Number(e.target.value))}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {Object.entries(types ?? {}).map((item) => (
                <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            { (typeId === 1) && (
              <>
                <label>{t('shop')}</label>
                <Select
                  displayEmpty
                  value={shopId}
                  onChange={e => setShopId(e.target.value)}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      classes={{
                        notchedOutline: globalStyles.mainChartSelectRoot,
                        input: globalStyles.mainChartSelect,
                      }}
                    />
                  }
                  fullWidth
                >
                  <MenuItem value="">{t('all')}</MenuItem>
                  {brandLocations.map((item) => (
                    <MenuItem key={item._id} value={item._id}>{item.brandName} - {item.code}</MenuItem>
                  ))}
                </Select>
              </>
            ) }
          </Grid>
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={3}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" sx={{ marginRight: "1rem" }} type="submit">
                {t('search')}
              </Button>
              <Button variant="contained" color="inherit" onClick={() => clearSearchData()}>
                {t('clear')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('type')}
                  </TableCell>
                  <TableCell>
                    {t('pointScheme.shop')}
                  </TableCell>
                  <TableCell>
                    {t('pointScheme.tier')}
                  </TableCell>
                  <TableCell>
                    {t('pointScheme.startDate')}
                  </TableCell>
                  <TableCell>
                    {t('pointScheme.endDate')}
                  </TableCell>
                  <TableCell align="center">
                    {t('status')}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  items?.length > 0 ? 
                  items.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.typeName}
                      </TableCell>
                      <TableCell>
                        {row.shop?.code}
                      </TableCell>
                      <TableCell>
                        {row?.tierName}
                      </TableCell>
                      <TableCell>
                        {row.startDate ? formatDatetimePicker(row.startDate) : ''}
                      </TableCell>
                      <TableCell>
                        {row.endDate ? formatDatetimePicker(row.endDate) : ''}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        <StatusLabel status={row.isActive}></StatusLabel>
                      </TableCell>
                      <TableCell style={{ width: 280 }} align="left" className={globalStyles.tableButton}>
                        <Button variant="contained" size="small" color="info" onClick={() => openDetail(row._id)}>
                          {t('view')}
                        </Button>
                        <Button variant="contained" size="small" color="primary" onClick={() => openEdit(row._id)}>
                          {t('edit')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )) : (
                    <TableRow >
                      <TableCell colSpan={9} >{ t('message.notAnyRecord') }</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={9}
                    count={totalItems}
                    rowsPerPage={pagingData.limitPerPage}
                    page={pagingData.page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(PointScheme);