import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import {
  Grid,
  Box,
} from "@material-ui/core";

// styles
import useGlobalStyles from "../../styles";
import { useHistory, useParams } from "react-router-dom";
import { getStaff } from "../../utils/api";
import DetailRow from "../../components/Table/DetailRow";
import { useTranslation } from "react-i18next";
import { showDateUTC } from "../../utils/helpers";

function StaffDetail(props) {
  var globalClasses = useGlobalStyles();
  const { t } = useTranslation('common');
  const history = useHistory();
  const { id } = useParams();
  const [item, setItem] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    const result = await getStaff(id);
    setItem(result);
    setIsShowLoading(false);
  }

  useEffect(() => {
    fetchItem()
  }, [])

  return (
    <>
      <PageTitle title={t('staff.detail')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/staff')}
        >
          { t('backToList') }
        </Button>}
      />
      <Box style={{marginBottom: "3rem"}}>
        <Grid className={globalClasses.containerDetail}>
          <DetailRow label={t('id')} value={item._id}/>
          <DetailRow label={t('name')} value={item.fullName}/>
          <DetailRow label={t('email')} value={item.email}/>
          <DetailRow label={t('phone')} value={item.phone}/>
          <DetailRow label={t('birthday')} value={showDateUTC(item.birthday)}/>
          <DetailRow label={t('birthMonth')} value={item.birthMonth}/>
          <DetailRow label={t('gender')} value={item.gender}/>
          <DetailRow label={t('receivePromoAndNotify')} value={item.receivePromoAndNotify}/>
          <DetailRow label={t('isActive')} value={item.accountActive ? 'Yes' : 'No'}/>
          <DetailRow label={t('createdAt')} value={showDateUTC(item.createdAt) }/>
          <DetailRow label={t('updatedAt')} value={showDateUTC(item.updatedAt) } isNoneBorder={true}/>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(StaffDetail);
