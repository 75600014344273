import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import {
    Grid,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Paper,
    TableRow,
    TableHead,
    Typography,
} from "@material-ui/core";

// styles
import useGlobalStyles from "../../styles";
import { useHistory, useParams } from "react-router-dom";
import { getMember } from "../../utils/api";
import DetailRow from "../../components/Table/DetailRow";
import { useTranslation } from "react-i18next";
import { showDateHK, showDateUTC } from "../../utils/helpers";

function MemberDetail(props) {
    var globalClasses = useGlobalStyles();
    const { t } = useTranslation("common");
    const history = useHistory();
    const { id } = useParams();
    const [item, setItem] = useState([]);
    const [isShowLoading, setIsShowLoading] = useState(false);

    const fetchItem = async () => {
        setIsShowLoading(true);
        const result = await getMember(id);
        setItem(result.memberDetail);
        setIsShowLoading(false);
    };

    useEffect(() => {
        fetchItem();
    }, []);

    return (
        <>
            <PageTitle
                title={t("member.detail")}
                button={
                    <Button
                        variant="contained"
                        size="medium"
                        color="inherit"
                        onClick={() => history.push("/member")}
                    >
                        {t("backToList")}
                    </Button>
                }
            />
            <Box style={{ marginBottom: "3rem" }}>
                <Grid className={globalClasses.containerDetail}>
                    <DetailRow label={t("id")} value={item?.membershipId || ""} />
                    <DetailRow label={t("name")} value={item.fullName} />
                    <DetailRow label={t("gender")} value={item.gender} />
                    <DetailRow label={t("birthday")} value={showDateUTC(item.birthday)} />
                    <DetailRow label={t("email")} value={item.email} />
                    <DetailRow label={t("phone")} value={item.phone} />
                    <DetailRow label={t("isActive")} value={item.accountActive ? "Yes" : "No"} />
                    <DetailRow
                        label={t("createdDate")}
                        value={item.createdAt}
                        isNoneBorder={true}
                    />
                </Grid>
            </Box>
            <Box style={{ marginBottom: "3rem" }}>
                <Grid className={globalClasses.containerDetail}>
                    <DetailRow
                        label={t("member.currentTier")}
                        value={item?.tierList?.[0]?.tierName ?? ""}
                    />
                    <DetailRow
                        label={t("member.tierStartDate")}
                        value={
                            item?.tierList?.[0]?.createdDate
                                ? showDateHK(item.tierList[0].createdDate)
                                : ""
                        }
                    />
                    <DetailRow
                        label={t("member.tierEndDate")}
                        value={
                            item?.tierList?.[0]?.expiredDate
                                ? showDateHK(item.tierList[0].expiredDate)
                                : ""
                        }
                    />
                    <DetailRow label={t("member.currentSpending")} value={item.spending} />
                    <DetailRow label={t("member.oldSystemGcoin")} value={item.osPoint} />
                    <DetailRow label={t("member.currentGCoin")} value={item.currentGCoins} />
                    <DetailRow label={t("member.expiredGCoins")} value={item.expiredGCoins} />
                </Grid>
            </Box>
            <div className={globalClasses.pageSubTitleContainer}>
                <Typography className={globalClasses.typo} variant="h6" size="sm">
                    {t("member.memberTransaction")}
                </Typography>
            </div>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 500 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("id")}</TableCell>
                                    <TableCell>{t("member.actionType")}</TableCell>
                                    <TableCell>{t("member.gCoin")}</TableCell>
                                    <TableCell>{t("member.invoiceNumber")}</TableCell>
                                    <TableCell>{t("spending")}</TableCell>
                                    <TableCell align="center">{t("createdAt")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item.transactionList?.length > 0 ? (
                                    item.transactionList.map((row) => (
                                        <TableRow key={row._id}>
                                            <TableCell component="th" scope="row">
                                                {row._id}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.actionName}
                                            </TableCell>
                                            <TableCell>
                                                {row.operator} {row.gCoinAmount}
                                            </TableCell>
                                            <TableCell>{row.invoice?.invoiceNumber}</TableCell>
                                            <TableCell>{row.invoice?.spendingAmount}</TableCell>
                                            <TableCell style={{ width: 160 }} align="center">
                                                {showDateUTC(row.createdAt)}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            {t("message.notAnyRecord")}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <div className={globalClasses.pageSubTitleContainer}>
                <Typography className={globalClasses.typo} variant="h6" size="sm">
                    {t("member.memberInvoices")}
                </Typography>
            </div>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 500 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("member.invoiceNumber")}</TableCell>
                                    <TableCell>{t("member.paymentAmount")}</TableCell>
                                    <TableCell>{t("member.shopCode")}</TableCell>
                                    <TableCell>{t("member.invoiceDate")}</TableCell>
                                    <TableCell align="center">{t("createdAt")}</TableCell>
                                    <TableCell align="center">{t("updatedAt")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item.invoiceList?.length > 0 ? (
                                    item.invoiceList.map((row) => (
                                        <TableRow key={row._id}>
                                            <TableCell component="th" scope="row">
                                                {row.invoiceNumber}
                                            </TableCell>
                                            <TableCell>{row.paymentAmount}</TableCell>
                                            <TableCell>{row.shopCode}</TableCell>
                                            <TableCell>{row.invoiceDate}</TableCell>
                                            <TableCell style={{ width: 160 }} align="center">
                                                {showDateUTC(row.createdAt)}
                                            </TableCell>
                                            <TableCell style={{ width: 160 }} align="center">
                                                {showDateUTC(row.updatedAt)}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            {t("message.notAnyRecord")}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isShowLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default withRouter(MemberDetail);
