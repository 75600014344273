import {
  Grid,
  Box
} from "@material-ui/core";
import { Typography } from "../Wrappers/Wrappers";

import useStyles from "./styles";

const DetailRow = (props) => {
    const classes = useStyles();
    const { label, value, isNoneBorder } = props;
  
    return (
      <Grid style={{ borderBottom: isNoneBorder ? "none" : "1px solid #999" }}>
        <Grid container spacing={4} >
          <Grid item xs={4}>
            <Typography variant="h6" color="info" style={{padding: "0.5rem 1rem"}}>
              { label }
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box
              component="img"
              sx={{
                width: 250,
              }}
              src={value}
            />
          </Grid>
        </Grid>
      </Grid>
    );
}

export default DetailRow