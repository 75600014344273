import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField, Select, OutlinedInput } from "@material-ui/core";
import {
  Button,
  Backdrop,
  CircularProgress,
  Alert,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
// styles
import useGlobalStyles from "../../styles";
import {
  updateBrandHotItem,
  createBrandHotItem,
  getBrands,
  getBrandAds,
  updateBrandAds,
  createBrandAds,
} from "../../utils/api";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  convertDataToViewMultilanguage,
  convertViewToFormData,
  setDefaultObject,
  showErrorToast,
  validateLanguageObject,
} from "../../utils/helpers";
import MultiLanguageTabs from "../../components/Common/MultiLanguageTabs";
import ImageUploader from "../../components/Common/ImageUploader";

function BrandHotItemForm(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation("common");
  const keys = ["campaignName"];
  const dataKeys = ["campaignName", "brandId", "isActive"];
  const filekeys = ["image"];
  const dataFormkeys = [...dataKeys, ...["imageUrl"]];
  const keyLangauge = "adsLang";

  const [item, setItem] = useState([]);
  const [groups, setGroups] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    if (id) {
      const itemData = await getBrandAds(id);
      itemData.brandId = itemData.brand?._id;
      delete itemData.brand;
      const formatDataLanguage = convertDataToViewMultilanguage(
        itemData,
        keys,
        keyLangauge
      );
      setItem(formatDataLanguage);
    } else {
      const defaultData = setDefaultObject(dataFormkeys, keys, keyLangauge);
      setItem(defaultData);
    }
    setIsShowLoading(false);
  };

  const updateObjectValue = (key, value) => {
    setItem((eve) => ({ ...eve, [key]: value }));
  };

  const fetchInitData = async () => {
    const brands = await getBrands({isActive: true});
    if (brands?.itemList) {
      setGroups(brands.itemList);
    }
  };

  useEffect(() => {
    fetchItem();
    fetchInitData();
  }, []);

  const languageRef = useRef()

  const submitForm = async (eve) => {
    eve.preventDefault();

    const resultValidate = validateLanguageObject(item[keyLangauge], keys)
    if (resultValidate){
      languageRef?.current?.handleActiveTab(resultValidate.indexTabLang)
      showErrorToast(`${t('requiredField', {field: resultValidate.key})}`)
      return;
    }
    
    const data = convertViewToFormData(
      item,
      keys,
      keyLangauge,
      dataKeys,
      filekeys
    );
    let result = {};
    if (id) {
      result = await updateBrandAds(id, data);
    } else {
      result = await createBrandAds(data);
    }
    if (result.status) {
      history.push("/brand-ads");
    } else {
      setErrorMessage(result?.data?.message || "");
    }
  };

  return (
    <>
      <PageTitle
        title={t("brandAds.form")}
        button={
          <Button
            variant="contained"
            size="medium"
            color="inherit"
            onClick={() => history.push("/brand-ads")}
          >
            {t("backToList")}
          </Button>
        }
      />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        {errorMessage !== "" ? (
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        ) : (
          <div></div>
        )}
        <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
          <Grid item xs={6}>
            <label>{t('brand.title')}</label>
            <Select
              required
              displayEmpty
              value={item.brandId || ""}
              onChange={(e) => updateObjectValue("brandId", e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              <MenuItem value="">
                {t("selectOne")} {t("brandGroup.title")}
              </MenuItem>
              {groups.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <ImageUploader
              value={item.imageUrl}
              updateObjectValue={(data) => updateObjectValue("image", data)}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12}>
            {item[keyLangauge] ? (
              <MultiLanguageTabs
                ref={languageRef}
                data={item[keyLangauge]}
                updateObjectValue={(data) =>
                  updateObjectValue(keyLangauge, data)
                }
                keys={keys}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.isActive || false}
                  onChange={(e) => {
                    updateObjectValue("isActive", e.target.checked);
                  }}
                />
              }
              label={t("IsActive")}
            />
          </Grid>
          <Grid item xs={9}></Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ marginRight: "1rem" }}
                type="submit"
              >
                {t("save")}
              </Button>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => history.push("/brand-ads")}
              >
                {t("cancel")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </>
  );
}

export default withRouter(BrandHotItemForm);
