import React, { useState, useEffect, useRef } from "react";
import { Grid, Select, OutlinedInput, MenuItem } from "@material-ui/core";
import { Button, Backdrop, CircularProgress, Alert } from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
// styles
import useGlobalStyles from "../../styles";
import {
  getCouponSelect,
  getTier,
  updateTier,
} from "../../utils/api";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  convertDataToViewMultilanguage,
  convertViewToDataMultilanguage,
  setDefaultObject,
  showErrorToast,
  validateLanguageObject,
} from "../../utils/helpers";
import MultiLanguageTabs from "../../components/Common/MultiLanguageTabs";
import {
  Add as AddIcon,
  Close as CloseIcon
} from "@material-ui/icons";

function TierForm(props) {
  const globalStyles = useGlobalStyles();
  
  const history = useHistory();
  const { id } = useParams();
  if (!id) {
    history.push("/tier");
  }
  const { t } = useTranslation("common");
  const keys = ["name"];
  const dataPostkeys = ["name", "offeredCoupons"];
  const keyLangauge = "tierLang";

  const [item, setItem] = useState({});
  const [coupons, setCoupons] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    if (id) {
      const itemData = await getTier(id);
      itemData.offeredCoupons = itemData.offeredCoupons.map(offCoupon => String(offCoupon._id))
      const formatDataLanguage = convertDataToViewMultilanguage(
        itemData,
        keys,
        keyLangauge
      );
      setItem(formatDataLanguage);
    } else {
      const defaultData = setDefaultObject(dataPostkeys, keys, keyLangauge);
      defaultData.offeredCoupons = []
      setItem(defaultData);
    }
    setIsShowLoading(false);
  };

  const fetchInitData = async () => {
    const coupons = await getCouponSelect(true);
    setCoupons(coupons);
  };

  const updateObjectValue = (key, value) => {
    setItem((eve) => ({ ...eve, [key]: value }));
  };

  const updateOfferedCoupons = (index, value) => {
    const offeredCoupons = [...item.offeredCoupons];
    offeredCoupons[index] = value
    updateObjectValue('offeredCoupons', offeredCoupons)
  }

  const addOfferedCoupons = () => {
    const offeredCoupons = [...item.offeredCoupons];
    offeredCoupons.push('')
    updateObjectValue('offeredCoupons', offeredCoupons)
  }

  const removeOfferedCoupons = (index) => {
    const offeredCoupons = [...item.offeredCoupons];
    offeredCoupons.splice(index, 1)
    updateObjectValue('offeredCoupons', offeredCoupons)
  }

  useEffect(() => {
    fetchInitData();
    fetchItem();
  }, []);

  const languageRef = useRef()
  
  const submitForm = async (eve) => {
    eve.preventDefault();

    const resultValidate = validateLanguageObject(item[keyLangauge], keys)
    if (resultValidate){
      languageRef?.current?.handleActiveTab(resultValidate.indexTabLang)
      showErrorToast(`${t('requiredField', {field: resultValidate.key})}`)
      return;
    }

    const data = convertViewToDataMultilanguage(
      item,
      keys,
      keyLangauge,
      dataPostkeys
    );
    let result = {};
    if (id) {
      result = await updateTier(id, data);
    }
    if (result.status) {
      history.push("/tier");
    } else {
      setErrorMessage(result?.data?.message || "");
    }
  };

  return (
    <>
      <PageTitle
        title={t("tier.form")}
        button={
          <Button
            variant="contained"
            size="medium"
            color="inherit"
            onClick={() => history.push("/tier")}
          >
            {t("backToList")}
          </Button>
        }
      />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        {errorMessage !== "" ? (
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        ) : (
          <div></div>
        )}
        <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
          <Grid item xs={12}>
            {item[keyLangauge] ? (
              <MultiLanguageTabs
                ref={languageRef}
                data={item[keyLangauge]}
                updateObjectValue={(data) =>
                  updateObjectValue(keyLangauge, data)
                }
                keys={keys}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12}>
            <label>{t('coupon.title')}</label>
            {
              item?.offeredCoupons?.map((offCoupon, index) => (
                  <Grid container spacing={4} style={{marginBottom: "1rem"}} key={index}>
                    <Grid item xs={6}>
                      <Select
                        displayEmpty
                        value={item.offeredCoupons[index] || []}
                        onChange={e => updateOfferedCoupons(index, e.target.value)}
                        input={
                          <OutlinedInput
                            labelWidth={0}
                            classes={{
                              notchedOutline: globalStyles.mainChartSelectRoot,
                              input: globalStyles.mainChartSelect,
                            }}
                          />
                        }
                        fullWidth
                      >
                        {Object.keys(coupons).map((couponId) => (
                          <MenuItem key={String(couponId)} value={String(couponId)}>{coupons[couponId]}</MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={4}>
                    {
                      (item.offeredCoupons.length > 1) && (
                        <button type="button" onClick={() => removeOfferedCoupons(index)} style={{marginRight: '1.5rem'}}><CloseIcon/></button>
                      )
                    }
                    {
                      (index === item.offeredCoupons.length - 1 && item.offeredCoupons.length > 1) && (
                        <button type="button" onClick={addOfferedCoupons}><AddIcon/></button>
                      )
                    }</Grid>
                  </Grid>
                )
              )
            }
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" sx={{ marginRight: "1rem" }} type="submit">
                {t('save')}
              </Button>
              <Button variant="contained" color="inherit" onClick={() => history.push('/tier')}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </>
  );
}

export default withRouter(TierForm);
