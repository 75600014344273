import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import { getAdmins } from "../../utils/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGlobalStyles from "../../styles";
import StatusLabel from "../../components/Table/StatusLabel";

function Admin(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { t } = useTranslation('common');

  const [items, setItems] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchList = async() => {
    setIsShowLoading(true);
    
    const result = await getAdmins({});
    setItems(result.itemList);
    setIsShowLoading(false);
  }

  useEffect(() => {
    fetchList();
  }, [])

  const openDetail = (id) => {
    history.push(`/admin/${id}`);
  }

  const openEdit = (id) => {
    history.push(`/admin/${id}/edit`);
  }

  return (
    <>
      <PageTitle title={t('admin.title')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/admin/add')}
        >
          { t('create') }
        </Button>}/>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('name')}
                  </TableCell>
                  <TableCell>
                    {t('email')}
                  </TableCell>
                  <TableCell>
                    {t('phone')}
                  </TableCell>
                  <TableCell>
                    {t('role')}
                  </TableCell>
                  <TableCell>
                    {t('status')}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  items?.length > 0 ? 
                  items.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>
                        {row.email}
                      </TableCell>
                      <TableCell>
                        {row.phone}
                      </TableCell>
                      <TableCell>
                        {row.roleName}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        <StatusLabel status={row.isActive}></StatusLabel>
                      </TableCell>
                      <TableCell style={{ width: 300 }} align="left" className={globalStyles.tableButton}>
                        <Button variant="contained" size="small" color="info" onClick={() => openDetail(row._id)}>
                          {t('view')}
                        </Button>
                        <Button variant="contained" size="small" color="primary" onClick={() => openEdit(row._id)}>
                          {t('edit')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )) : (
                    <TableRow >
                      <TableCell colSpan={8} >{ t('message.notAnyRecord') }</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(Admin);