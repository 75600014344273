export const MULTIPLE_LANGUAGES = ['en', 'zh', 'cn']

export const ADMIN_ROLE = {
    supperAdmin: 'supperAdmin',
    admin: 'admin',
}

export const PUSH_TYPES = {
    instant: 1,
    specificDateTime: 2,
    daily: 3,
    weekly: 4,
    monthly: 5,
    yearly: 6,
}

export const PUSH_METHODS = {
    all: 1,
    someone: 2,
    conditions: 3,
    group: 4
}

export const PUSH_MEMBER_GROUP_TYPE = {
    all: 'all',
    gender: 'gender',
    ghostCustomer: 'ghostCustomer',
    newMember: 'newMember',
    useCoupon: 'useCoupon',
    scanInvoice: 'scanInvoice',
    endPhoneNumber: 'endPhoneNumber',
    scanInvoiceInDay: 'scanInvoiceInDay',
    scanInvoiceInTimeSlot: 'scanInvoiceInTimeSlot',
}

export const DATE_ONLY_FORMAT = 'YYYY-MM-DD'

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'

export const GENDER = {
    male: 'male',
    female: 'female'
}

export const HONGKONG_TIMEZONE_NAME = 'Asia/Hong_Kong';