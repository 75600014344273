import {
  Grid,
} from "@material-ui/core";
import { Typography } from "../Wrappers";
import { useHistory } from "react-router-dom";

const DetailRowLink = (props) => {
    const { label, value, link, isNoneBorder } = props;
    const history = useHistory();
  
    return (
      <Grid style={{ borderBottom: isNoneBorder ? "none" : "1px solid #999" }}>
        <Grid container spacing={4} >
          <Grid item xs={4}>
            <Typography variant="h6" color="info" style={{padding: "0.5rem 1rem"}}>
              { label }
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography size="md" style={{padding: "0.5rem 1rem"}}>
              <a onClick={() => history.push(link)} style={{cursor: 'pointer', textDecoration: 'underline'}}>
                { value }
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
}

export default DetailRowLink