import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import {
  Grid,
  Box,
} from "@material-ui/core";

// styles
import useGlobalStyles from "../../styles";
import { useHistory, useParams } from "react-router-dom";
import { getPointScheme } from "../../utils/api";
import DetailRow from "../../components/Table/DetailRow";
import { useTranslation } from "react-i18next";
import { showDateUTC } from "../../utils/helpers";

function PointSchemeDetail(props) {
  var globalClasses = useGlobalStyles();
  const { t } = useTranslation('common');
  const history = useHistory();
  const { id } = useParams();
  const [item, setItem] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    const result = await getPointScheme(id);
    setItem(result);
    setIsShowLoading(false);
  }

  useEffect(() => {
    fetchItem()
  }, [])

  return (
    <>
      <PageTitle title={t('pointScheme.detail')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/point-scheme')}
        >
          { t('backToList') }
        </Button>}
      />
      <Box style={{marginBottom: "3rem"}}>
        <Grid className={globalClasses.containerDetail}>
          <DetailRow label={t('id')} value={item._id}/>
          <DetailRow label={t('type')} value={item.typeName}/>
          <DetailRow label={t('pointScheme.shop')} value={item?.shop?.name ?? ''}/>
          <DetailRow label={t('pointScheme.tier')} value={item?.tierName ?? ''}/>
          <DetailRow label={t('pointScheme.startDate')} value={item?.startDate ?? ''}/>
          <DetailRow label={t('pointScheme.endDate')} value={item?.endDate ?? ''}/>
          <DetailRow label={t('pointScheme.weekday')} value={item?.weekday ?? ''}/>
          <DetailRow label={t('pointScheme.day')} value={item?.day ?? ''}/>
          <DetailRow label={t('pointScheme.startTime')} value={item?.startTime ?? ''}/>
          <DetailRow label={t('pointScheme.endTime')} value={item?.endTime ?? ''}/>
          <DetailRow label={t('pointScheme.ratio')} value={item?.ratio ?? ''}/>
          <DetailRow label={t('isActive')} value={item.isActive ? 'Yes' : 'No'}/>
          <DetailRow label={t('createdAt')} value={showDateUTC(item.createdAt) }/>
          <DetailRow label={t('updatedAt')} value={showDateUTC(item.updatedAt) } isNoneBorder={true}/>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(PointSchemeDetail);
