import { result } from "lodash"
import axiosInstance from "../components/Common/AxiosInstance"

export async function login(payload) {
    const result = await axiosInstance.post('admin/login', payload)
    return result.data
}

export async function getExportMembers(payload){
    try {
        const result = await axiosInstance.get('admin-member/members/export-data', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getMembers(payload) {
    try {
        const result = await axiosInstance.get('admin-member/members', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getMember(id) {
    try {
        const result = await axiosInstance.get(`admin-member/members/${id}`)
        return result.data
    } catch (e) {
        return null
    }
}

export async function changeStatusMember(id, block) {
    try {
        const result = await axiosInstance.post(`admin-member/members/${id}/blocking`, { block })
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

{/* Author: SangVTL - Date: 12/07/2024 */}
export async function getExportMemberTransactions(payload){
    try {
        const result = await axiosInstance.get('admin-member/member-transactions/export-data', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

{/* Author: SangVTL - Date: 13/08/2024 */}
export async function deleteFileUserTransaction(){
    try {
        const result = await axiosInstance.get('admin-member/member-transactions/delete-file')
        return result.data
    } catch (e) {
        return null
    }
}

export async function getMemberTransctions(payload) {
    try {
        const result = await axiosInstance.get('admin-member/member-transactions', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getMemberTransaction(id) {
    try {
        const result = await axiosInstance.get(`admin-member/member-transactions/${id}`)
        return result.data
    } catch (e) {
        return null
    }
}

export async function getTransactioTypes() {
    try {
        const result = await axiosInstance.get('admin-member/member-transaction-types')
        return result.data
    } catch (e) {
        return null
    }
}

export async function getMemberCoupons(payload) {
    try {
        const result = await axiosInstance.get('admin-member/member-coupons', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getMemberCoupon(id) {
    try {
        const result = await axiosInstance.get(`admin-member/member-coupons/${id}`)
        return result.data
    } catch (e) {
        return null
    }
}

export async function updateMemberPoint(payload) {
    try {
        const result = await axiosInstance.put(`admin-member/members/add-gcoins`, payload)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            message: e.response?.data?.message ?? e.message
        }
    }
}

export async function getCouponSelect(isGift = null) {
    try {
        const result = await axiosInstance.get('admin-coupon/coupon-data', {
            params: { isGift }
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getBrandGroups(payload) {
    try {
        const result = await axiosInstance.get('admin-brand/groups', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getBrandGroup(id) {
    try {
        const result = await axiosInstance.get(`admin-brand/groups/${id}`)
        return result.data
    } catch (e) {
        return null
    }

}

export async function deleteBrandGroup(id) {
    try {
        const result = await axiosInstance.delete(`admin-brand/groups/${id}`)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function createBrandGroup(payload) {
    try {
        const result = await axiosInstance.post('admin-brand/groups', payload)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function updateBrandGroup(id, payload) {
    try {
        const result = await axiosInstance.post(`admin-brand/groups/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function getCoupons(payload) {
    try {
        const result = await axiosInstance.get('admin-coupon/coupons', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getCoupon(id) {
    try {
        const result = await axiosInstance.get(`admin-coupon/coupons/${id}`)
        return result.data
    } catch (e) {
        return null
    }

}

export async function deleteCoupon(id) {
    try {
        const result = await axiosInstance.delete(`admin-coupon/coupons/${id}`)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function createCoupon(payload) {
    try {
        const result = await axiosInstance.post('admin-coupon/coupons', payload)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function updateCoupon(id, payload) {
    try {
        const result = await axiosInstance.put(`admin-coupon/coupons/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}


export async function getBrands(payload) {
    try {
        const result = await axiosInstance.get('admin-brand/brands', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getBrand(id) {
    try {
        const result = await axiosInstance.get(`admin-brand/brands/${id}`)
        return result.data
    } catch (e) {
        return null
    }
}

export async function deleteBrand(id) {
    try {
        const result = await axiosInstance.delete(`admin-brand/brands/${id}`)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function createBrand(payload) {
    try {
        const result = await axiosInstance.post('admin-brand/brands', payload)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function updateBrand(id, payload) {
    try {
        const result = await axiosInstance.put(`admin-brand/brands/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function getNewsList(payload) {
    try {
        const result = await axiosInstance.get('admin-brand/news', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getNews(id) {
    try {
        const result = await axiosInstance.get(`admin-brand/news/${id}`)
        return result.data
    } catch (e) {
        return null
    }

}

export async function deleteNews(id) {
    try {
        const result = await axiosInstance.delete(`admin-brand/news/${id}`)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function createNews(payload) {
    try {
        const result = await axiosInstance.post('admin-brand/news', payload)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function updateNews(id, payload) {
    try {
        const result = await axiosInstance.put(`admin-brand/news/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function getAllNewsList() {
    try {
        const result = await axiosInstance.get('admin-brand/news-all')
        return result.data
    } catch (e) {
        return null
    }
}


export async function getBrandHotItems(payload) {
    try {
        const result = await axiosInstance.get('admin-brand/items', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getBrandHotItem(id) {
    try {
        const result = await axiosInstance.get(`admin-brand/items/${id}`)
        return result.data
    } catch (e) {
        return null
    }

}

export async function deleteBrandHotItem(id) {
    try {
        const result = await axiosInstance.delete(`admin-brand/items/${id}`)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function createBrandHotItem(payload) {
    try {
        const result = await axiosInstance.post('admin-brand/items', payload)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function updateBrandHotItem(id, payload) {
    try {
        const result = await axiosInstance.put(`admin-brand/items/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}


export async function getBrandLocations(payload) {
    try {
        const result = await axiosInstance.get('admin-brand/locations', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getBrandLocation(id) {
    try {
        const result = await axiosInstance.get(`admin-brand/locations/${id}`)
        return result.data
    } catch (e) {
        return null
    }

}

export async function deleteBrandLocation(id) {
    try {
        const result = await axiosInstance.delete(`admin-brand/locations/${id}`)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function createBrandLocation(payload) {
    try {
        const result = await axiosInstance.post('admin-brand/locations', payload)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function updateBrandLocation(id, payload) {
    try {
        const result = await axiosInstance.put(`admin-brand/locations/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function getPaymentMethods() {
    try {
        const result = await axiosInstance.get('admin-brand/payment-methods')
        return result.data
    } catch (e) {
        return null
    }
}


export async function getBrandAdsList(payload) {
    try {
        const result = await axiosInstance.get('admin-brand/ads', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getBrandAds(id) {
    try {
        const result = await axiosInstance.get(`admin-brand/ads/${id}`)
        return result.data
    } catch (e) {
        return null
    }

}

export async function deleteBrandAds(id) {
    try {
        const result = await axiosInstance.delete(`admin-brand/ads/${id}`)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function createBrandAds(payload) {
    try {
        const result = await axiosInstance.post('admin-brand/ads', payload)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function updateBrandAds(id, payload) {
    try {
        const result = await axiosInstance.put(`admin-brand/ads/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function getAllBrandAdsList() {
    try {
        const result = await axiosInstance.get('admin-brand/ads-all')
        return result.data
    } catch (e) {
        return null
    }
}


export async function getPolicy(id) {
    try {
        const result = await axiosInstance.get('admin-setting/policy')
        return result.data
    } catch (e) {
        return null
    }
}

export async function updatePolicy(id, payload) {
    try {
        const result = await axiosInstance.put(`admin-setting/policy/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}


export async function getPointSchemes(payload) {
    try {
        const result = await axiosInstance.get('admin-member/point-schemes', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getPointScheme(id) {
    try {
        const result = await axiosInstance.get(`admin-member/point-schemes/${id}`)
        return result.data
    } catch (e) {
        return null
    }
}

export async function createPointScheme(payload) {
    try {
        const result = await axiosInstance.post('admin-member/point-schemes', payload)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function updatePointScheme(id, payload) {
    try {
        const result = await axiosInstance.put(`admin-member/point-schemes/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function getPointSchemeTypes() {
    try {
        const result = await axiosInstance.get('admin-member/point-scheme-types')
        return result.data
    } catch (e) {
        return null
    }
}


export async function getBrandLocationData(payload) {
    try {
        const result = await axiosInstance.get(`admin-brand/location-datas`, {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getTierData() {
    try {
        const result = await axiosInstance.get(`admin-setting/tier-datas`)
        return result.data
    } catch (e) {
        return null
    }
}

export async function getWeekday() {
    try {
        const result = await axiosInstance.get(`admin-setting/weekdays`)
        return result.data
    } catch (e) {
        return null
    }
}


export async function getAdmins(payload) {
    try {
        const result = await axiosInstance.get('admin/admins', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getAdmin(id) {
    try {
        const result = await axiosInstance.get(`admin/admins/${id}`)
        return result.data
    } catch (e) {
        return null
    }
}

export async function createAdmin(payload) {
    try {
        const result = await axiosInstance.post('admin/admins', payload)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function updateAdmin(id, payload) {
    try {
        const result = await axiosInstance.put(`admin/admins/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function assignCoupon(payload) {
    try {
        const result = await axiosInstance.post('admin-member/member-coupons/assign-multi', payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}


export async function getPushes(payload) {
    try {
        const result = await axiosInstance.get('admin-push/pushes', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getPush(id) {
    try {
        const result = await axiosInstance.get(`admin-push/pushes/${id}`)
        return result.data
    } catch (e) {
        return null
    }
}

export async function createPush(payload) {
    try {
        const result = await axiosInstance.post('admin-push/pushes', payload)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function updatePush(id, payload) {
    try {
        const result = await axiosInstance.put(`admin-push/pushes/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function getPushTypes() {
    try {
        const result = await axiosInstance.get('admin-push/types')
        return result.data
    } catch (e) {
        return null
    }
}

export async function getPushContentTypes() {
    try {
        const result = await axiosInstance.get('admin-push/content-types')
        return result.data
    } catch (e) {
        return null
    }
}

export async function getPushMethods() {
    try {
        const result = await axiosInstance.get('admin-push/methods')
        return result.data
    } catch (e) {
        return null
    }
}


export async function getStaffs(payload) {
    try {
        const result = await axiosInstance.get('admin-member/staffs', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getStaff(id) {
    try {
        const result = await axiosInstance.get(`admin-member/staffs/${id}`)
        return result.data
    } catch (e) {
        return null
    }
}

export async function createStaff(payload) {
    try {
        const result = await axiosInstance.post('admin-member/staffs', payload)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function updateStaff(id, payload) {
    try {
        const result = await axiosInstance.put(`admin-member/staffs/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}


export async function getReport(url, payload) {
    try {
        const result = await axiosInstance.get(url, {
            params: payload
        })
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function getPartners(payload) {
    try {
        const result = await axiosInstance.get('admin-member/partners', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getPartner(id) {
    try {
        const result = await axiosInstance.get(`admin-member/partners/${id}`)
        return result.data
    } catch (e) {
        return null
    }
}

export async function createPartner(payload) {
    try {
        const result = await axiosInstance.post('admin-member/partners', payload)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function updatePartner(id, payload) {
    try {
        const result = await axiosInstance.put(`admin-member/partners/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}


export async function getTiers(payload) {
    try {
        const result = await axiosInstance.get('admin-setting/tier', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getTier(id) {
    try {
        const result = await axiosInstance.get(`admin-setting/tier/${id}`)
        return result.data
    } catch (e) {
        return null
    }
}

export async function updateTier(id, payload) {
    try {
        const result = await axiosInstance.put(`admin-setting/tier/${id}`, payload)

        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function getOldMembers(payload) {
    try {
        const result = await axiosInstance.get('admin-member/old-members', {
            params: payload
        })
        return result.data
    } catch (e) {
        return null
    }
}

export async function getOldMember(id) {
    try {
        const result = await axiosInstance.get(`admin-member/old-members/${id}`)
        return result.data
    } catch (e) {
        return null
    }
}

export async function getReportPushData() {
    try {
        const result = await axiosInstance.get('admin-push/report-data')
        return result.data
    } catch (e) {
        return null
    }
}

export async function saveReportPush(data) {
    try {
        const result = await axiosInstance.post('admin-push/report-push', data)
        return {
            status: true,
            data: result.data
        }
    } catch (e) {
        return {
            status: false,
            data: e.response?.data ?? e.message
        }
    }
}

export async function countTotalMemberFollowGroup(data) {
    try {
        const result = await axiosInstance.get('admin-member/count-member-group', {
            params: data
        })
        return result.data
    } catch (e) {
        return null
    }
}