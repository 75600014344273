import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";
import { useTranslation } from "react-i18next";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import iconEn from './en.png';
import iconZh from './zh.png';

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  const {t, i18n} = useTranslation('common');

  // local
  var [profileMenu, setProfileMenu] = useState(null);
  var [languageMenu, setLanguageMenu] = useState(null);
  var [memberData, setMemberData] = useState({});
  
  useEffect(() => {
    const member = localStorage.getItem('admin_detail');
    if(member){
      setMemberData(JSON.parse(member));
    }
    i18n.changeLanguage(localStorage.getItem("language") ?? 'en')
  }, [])

  const changeLanguage = (alias) => {
    setLanguageMenu(null)
    localStorage.setItem("language", alias)
    userDispatch({type: 'CHANGE_LANGUAGE', lang: alias})
    i18n.changeLanguage(alias)
  }
  
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          Globallink Admin
        </Typography>
        <div className={classes.grow} />
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setLanguageMenu(e.currentTarget)}
        >
          <img src={i18n.language === 'en' ? iconEn : iconZh} className={classes.headerFlagIcon}/>
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(languageMenu)}
          anchorEl={languageMenu}
          onClose={() => setLanguageMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.languageMenuItem} onClick={() => changeLanguage('en')}>
            <img src={iconEn} className={classes.headerFlagIcon}/>
            <div className={classes.languageMenuItemText}>
              {t('en')}
            </div>
          </div>
          <div className={classes.languageMenuItem} onClick={() => changeLanguage('zh')}>
            <img src={iconZh} className={classes.headerFlagIcon}/>
            <div className={classes.languageMenuItemText}>
              {t('zh')}
            </div>
          </div>
          <div className={classes.languageMenuItem} onClick={() => changeLanguage('zh')}>
            <img src={iconZh} className={classes.headerFlagIcon}/>
            <div className={classes.languageMenuItemText}>
              {t('cn')}
            </div>
          </div>
        </Menu>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              { memberData.name }
            </Typography>
          </div>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
