import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import {
  Grid,
  Box,
} from "@material-ui/core";

// styles
import useGlobalStyles from "../../styles";
import { useHistory, useParams } from "react-router-dom";
import { getBrandGroup } from "../../utils/api";
import DetailRow from "../../components/Table/DetailRow";
import MultiLanguageDetailRow from "../../components/Table/MultiLanguageDetailRow";
import { useTranslation } from "react-i18next";
import { showDateUTC } from "../../utils/helpers";

function BrandGroupDetail(props) {
  var globalClasses = useGlobalStyles();
  const { t } = useTranslation('common');
  const history = useHistory();
  const { id } = useParams();
  const [item, setItem] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    const result = await getBrandGroup(id);
    setItem(result);
    setIsShowLoading(false);
  }

  useEffect(() => {
    fetchItem()
  }, [])

  return (
    <>
      <PageTitle title={t('brandGroup.detail')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/brand-group')}
        >
          { t('backToList') }
        </Button>}
      />
      <Box style={{marginBottom: "3rem"}}>
        <Grid className={globalClasses.containerDetail}>
          <DetailRow label={t('id')} value={item._id}/>
          <DetailRow label={t('code')} value={item.code}/>
          <MultiLanguageDetailRow label={t('name')} value={item.name}/>
          <MultiLanguageDetailRow label={t('description')} value={item.description}/>
          <DetailRow label={t('screenOrder')} value={item.screenOrder}/>
          <DetailRow label={t('createdAt')} value={showDateUTC(item.createdAt) }/>
          <DetailRow label={t('updatedAt')} value={showDateUTC(item.updatedAt) } isNoneBorder={true}/>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(BrandGroupDetail);
