import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TextField,
  TableFooter,
  TablePagination,
  Select,
  MenuItem,
  OutlinedInput
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import { getOldMembers } from "../../utils/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGlobalStyles from "../../styles";
import StatusLabel from "../../components/Table/StatusLabel";
import TablePaginationActions from "../../components/Table/TablePaginationActions";

function OldMember(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { t } = useTranslation('common');

  const defaultPaging = {
    sortName: "",
    sortType: "",
    limitPerPage: 20,
    page: 0,
  };

  const [pagingData, setPagingData] = useState(defaultPaging);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [activeType, setActiveType] = useState("all");

  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchList = async() => {
    setIsShowLoading(true);
    const data = {
      ...pagingData,
      page: pagingData.page + 1,
      email,
      phone,
      activeType
    };
    
    const result = await getOldMembers(data);
    setTotalItems(result.totalItems ?? 0);
    setItems(result.itemList);
    setIsShowLoading(false);
  }

  useEffect(() => {
    fetchList();
  }, [pagingData])

  const handleChangePage = (event, newPage) => {
    const data = pagingData;
    data.page = newPage;
    setPagingData(data);
    fetchList();
  };

  const clearSearchData = () => {
    setPagingData(defaultPaging);
    setEmail("");
    setPhone("");
    setActiveType("all");
  };
  
  const submitForm = (eve) => {
    eve.preventDefault();
    const data = pagingData
    data.page = 0
    setPagingData(data);
    fetchList();
  };

  const openDetail = (id) => {
    history.push(`/old-member/${id}`);
  }

  return (
    <>
      <PageTitle title={t('oldMember.title')} />
      
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
          <Grid item xs={3}>
            <TextField
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              label={t("phone")}
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label={t("email")}
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <label>{t('isActiveOldAccount')}</label>
            <Select
              value={activeType}
              onChange={e => { setActiveType(e.target.value) }}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              margin="normal"
              fullWidth
            >
              <MenuItem value="all">{t('all')}</MenuItem>
              <MenuItem value="yes">{t('active')}</MenuItem>
              <MenuItem value="no">{t('deactive')}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <Grid container justifyContent="flex-end">
              <div>
                <Button
                  sx={{ minWidth: "90px", marginRight: '10px' }}
                  variant="contained"
                  type="submit"
                >
                  {t("search")}
                </Button>
                <Button
                  sx={{ minWidth: "90px", marginRight: '10px' }}
                  variant="contained"
                  color="inherit"
                  onClick={() => clearSearchData()}
                >
                  {t("clear")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('name')}
                  </TableCell>
                  <TableCell>
                    {t('phone')}
                  </TableCell>
                  <TableCell>
                    {t('email')}
                  </TableCell>
                  <TableCell>
                    {t('member.oldSystemGcoin')}
                  </TableCell>
                  <TableCell>
                    {t('isActiveOldAccount')}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  items?.length > 0 ? 
                  items.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.name1 ?? row.name2 ?? ''}
                      </TableCell>
                      <TableCell>
                        {row.mobile}
                      </TableCell>
                      <TableCell>
                        {row?.email ?? ''}
                      </TableCell>
                      <TableCell>
                        {row.point}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        <StatusLabel status={row.isActived}></StatusLabel>
                      </TableCell>
                      <TableCell style={{ width: 300 }} align="left" className={globalStyles.tableButton}>
                        <Button variant="contained" size="small" color="info" onClick={() => openDetail(row._id)}>
                          {t('view')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )) : (
                    <TableRow >
                      <TableCell colSpan={8} >{ t('message.notAnyRecord') }</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={3}
                    count={totalItems}
                    rowsPerPage={pagingData.limitPerPage}
                    page={pagingData.page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(OldMember);