import React from "react";
import { login as loginMember } from "../utils/api";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "CHANGE_LANGUAGE":
      return { ...state, language: action.lang };
    default: {
      return { ...state, isAuthenticated: false };
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
    language: localStorage.getItem("language") ?? 'en'
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

async function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError('');
  setIsLoading(true);

  if (!!login && !!password) {
    try{
      const data = await loginMember({
        email: login,
        password: password
      })
  
      const { token, adminDetail } = data;
      localStorage.setItem('id_token', token);
      localStorage.setItem('admin_detail', JSON.stringify(adminDetail));
      setError('');
      setIsLoading(false);
      dispatch({ type: 'LOGIN_SUCCESS' });
      history.push('/dashboard');
    }catch(error){
      const errorData = error?.response?.data?.errorMessage ?? 'Error System!!!'
      setError(errorData);
      setIsLoading(false);
    }
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError('Email and Password must be not empty.');
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.removeItem("admin_detail");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
