import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Box
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import useGlobalStyles from "../../styles";
import { deleteBrand, getBrands } from "../../utils/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StatusLabel from "../../components/Table/StatusLabel";
import { showErrorToast } from "../../utils/helpers";

function Brand(props) {
  const history = useHistory();
  const { t } = useTranslation('common');

  const globalStyles = useGlobalStyles();
  const [items, setItems] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const fetchList = async() => {
    setIsShowLoading(true);
    
    const result = await getBrands({});
    setItems(result.itemList);
    setIsShowLoading(false);
  }

  useEffect(() => {
    fetchList();
  }, [])

  const openDetail = (id) => {
    history.push(`/brand/${id}`);
  }

  const openEdit = (id) => {
    history.push(`/brand/${id}/edit`);
  }

  const deleteItem = (item) => {
    setCurrentItem(item)
    setIsOpenDialog(true)
  }

  const confirmDelete = async () => {
    let result = await deleteBrand(currentItem._id)
    setIsOpenDialog(false)
    if(result.status) {
      fetchList()
    } else {
      showErrorToast(result?.data?.message || '')
    }
  }

  return (
    <>
      <PageTitle title={t('brand.title')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/brand/add')}
        >
          { t('create') }
        </Button>}/>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('thumbnail')}
                  </TableCell>
                  <TableCell>
                    {t('name')}
                  </TableCell>
                  <TableCell>
                    {t('isCommingSoon')}
                  </TableCell>
                  <TableCell align="center">
                    {t('status')}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  items?.length > 0 ? 
                  items.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        <Box
                          component="img"
                          sx={{
                            width: 200,
                          }}
                          alt={row.name}
                          src={row.logoUrl}
                        />
                      </TableCell>
                      <TableCell>
                        {row.name}
                      </TableCell>
                      <TableCell>
                        {row.comingSoon?.isComingSoon ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        <StatusLabel status={row.isActive}></StatusLabel>
                      </TableCell>
                      <TableCell style={{ width: 300 }} align="left" className={globalStyles.tableButton}>
                        <Button variant="contained" size="small" color="info" onClick={() => openDetail(row._id)}>
                          {t('view')}
                        </Button>
                        <Button variant="contained" size="small" color="primary" onClick={() => openEdit(row._id)}>
                          {t('edit')}
                        </Button>
                        <Button variant="contained" size="small" color="warning" onClick={() => deleteItem(row)}>
                        {t('delete')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )) : (
                    <TableRow >
                      <TableCell colSpan={8} >{ t('message.notAnyRecord') }</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          { t('warning') }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { 
              t('confirmDeleteItem', { 
                name: currentItem.name
              })
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpenDialog(false)}>{t('cancel')}</Button>
          <Button onClick={confirmDelete} autoFocus>
            {t('continuous')}
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(Brand);