import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import {
  Grid,
  Box,
} from "@material-ui/core";

// styles
import useGlobalStyles from "../../styles";
import { useHistory, useParams } from "react-router-dom";
import { getMemberCoupon } from "../../utils/api";
import DetailRow from "../../components/Table/DetailRow";
import { useTranslation } from "react-i18next";
import { showDateUTC } from "../../utils/helpers";

function MemberCouponDetail(props) {
  var globalClasses = useGlobalStyles();
  const { t } = useTranslation('common');
  const history = useHistory();
  const { id } = useParams();
  const [item, setItem] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    const result = await getMemberCoupon(id);
    setItem(result);
    setIsShowLoading(false);
  }

  useEffect(() => {
    fetchItem()
  }, [])

  return (
    <>
      <PageTitle title={t('member.memberCouponDetail')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/member-coupon')}
        >
          { t('backToList') }
        </Button>}
      />
      <Box style={{marginBottom: "3rem"}}>
        <Grid className={globalClasses.containerDetail}>
          <DetailRow label={t('id')} value={item._id}/>
          <DetailRow label={t('name')} value={item.member?.fullName}/>
          <DetailRow label={t('phone')} value={item.member?.phone}/>
          <DetailRow label={t('coupon.title')} value={item.coupon?.name || ''}/>
          <DetailRow label={t('member.expiredDate')} value={item.expiredDate}/>
          <DetailRow label={t('member.usedDate')} value={item?.usedDate || ''}/>
          <DetailRow label={t('createdAt')} value={showDateUTC(item.createdAt) } isNoneBorder={true}/>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(MemberCouponDetail);
