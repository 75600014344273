import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  chartWrapper: {
    margin: "0px auto",
    display: "flex",

    justifyContent: "center",
    paddingTop: theme.spacing(2),
  },
  barChartWrapper: {
    width: "100%",
    height: "100%",
    ["@media (max-width: 568px)"]: { width: "100%" },
  },
  pieChartWrapper: {
    width: "60%",
    height: "100%",
    ["@media (max-width: 568px)"]: { width: "100%" },
  },
  datePickerWrapper: {
    display: "flex",
    "& label": {
      top: '-10px'
    },
    gap: "1.5rem",
    ["@media (max-width: 568px)"]: { flexDirection: "column" },
  },
  actionReportWrapper: {
    display: "flex",
    gap: "1.5rem",
    marginBottom: "2rem",
    alignItems: "center",
    "& input": {
      padding: "0.25rem",
      width: '90%'
    },
    ["@media (max-width: 568px)"]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
}));
