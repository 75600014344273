import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Select,
  OutlinedInput
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress, Alert, MenuItem, Radio, RadioGroup, FormControlLabel, Checkbox} from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import useGlobalStyles from "../../styles";
import { getAllNewsList, getPushContentTypes, getAllBrandAdsList, getReportPushData, countTotalMemberFollowGroup, saveReportPush } from "../../utils/api";
import MultiLanguageTabs from "../../components/Common/MultiLanguageTabs";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { convertViewToFormData, setDefaultObject, showErrorToast, showSuccessToast, validateLanguageObject } from "../../utils/helpers";
import { MULTIPLE_LANGUAGES, PUSH_MEMBER_GROUP_TYPE } from "../../utils/constant";

function PushForm(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation('common');
  const keys = ["name", "content"];
  const dataKeys = ['memberGroupType', 'month', 'timeSlot', 'gender',
    'name', 'content', 'contentType', 'newsId', 'adsId' ];
  const arrayKeys = ['phoneNumbers', 'dayOfWeeks']
  const keyLangauge = "pushLang";

  const [item, setItem] = useState({
    memberGroupType: 'all'
  });
  const [types, setTypes] = useState({});
  const [genders, setGenders] = useState({});
  const [weekdays, setWeekdays] = useState({});
  const [timeSlots, setTimeslots] = useState([]);
  const [contentTypes, setContentTypes] = useState({});
  const [news, setNews] = useState([]);
  const [ads, setAds] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [messageReport, setMessageReport] = useState(false);

  const months = [
    {value: 1, name: '1 month'},
    {value: 3, name: '3 month'},
    {value: 6, name: '6 month'},
    {value: 12, name: '12 month'},
  ]

  const [isShowLoading, setIsShowLoading] = useState(false);

  const languageRef = useRef();

  const fetchItem = async () => {
    setIsShowLoading(true);
    const defaultData = setDefaultObject(dataKeys, keys, keyLangauge);
    defaultData.memberGroupType = 'all'
    defaultData.phoneNumbers = []
    defaultData.dayOfWeeks = []
    setItem(defaultData)
    setIsShowLoading(false);
  }

  const updateObjectValue = (key, value) => {
    setItem(eve => ({...eve, [key]: value}))
  }

  const updatePhoneNumbers = (isChecked, number) => {
    const phoneNumbers = [...item.phoneNumbers]
    if (isChecked){
      if (!phoneNumbers.includes(number)) {
        phoneNumbers.push(number)
      }
    } else {
      const indexNumber = phoneNumbers.indexOf(number)
      if (indexNumber > -1) {
        phoneNumbers.splice(indexNumber, 1);
      }
    }
    updateObjectValue('phoneNumbers', phoneNumbers)
  }

  const updateDayOfWeek = (isChecked, day) => {
    const dayOfWeeks = [...item.dayOfWeeks]
    if (isChecked){
      if (!dayOfWeeks.includes(day)) {
        dayOfWeeks.push(day)
      }
    } else {
      const indexDay = dayOfWeeks.indexOf(day)
      if (indexDay > -1) {
        dayOfWeeks.splice(indexDay, 1);
      }
    }
    updateObjectValue('dayOfWeeks', dayOfWeeks)
  }

  const updateMemberGroupType = (value) => {
    setItem(eve => ({...eve, memberGroupType: value, month: '', phoneNumbers: [], dayOfWeeks: [], timeSlot: '', gender: ''}))
  }

  const fetchInitData = async () => {
    const data = await getReportPushData()
    setTypes(data?.memberGroupTypes ?? {})
    setGenders(data?.genders ?? {})
    setWeekdays(data?.weekDays ?? {})
    setTimeslots(data?.timeSlots ?? [])

    const dataContentTypes = await getPushContentTypes()
    setContentTypes(dataContentTypes ?? {})
    const newsList = await getAllNewsList()
    setNews(newsList ?? [])
    const adsList = await getAllBrandAdsList()
    setAds(adsList ?? [])
  }

  const fetchTotalMember = async () => {
    if (item.memberGroupType !== ''){
      if (item.memberGroupType === 'gender' && item.gender === ''){
        return;
      }
      if ((item.memberGroupType === 'ghostCustomer' || item.memberGroupType === 'newMember' ||
      item.memberGroupType === 'scanInvoice' || item.memberGroupType === 'useCoupon') && item.month === ''){
        return;
      }
      if (item.memberGroupType === 'endPhoneNumber' && item.phoneNumbers.length === 0){
        return;
      }
      if (item.memberGroupType === 'scanInvoiceInDay' && item.dayOfWeeks.length === 0){
        return;
      }
      if (item.memberGroupType === 'scanInvoiceInTimeSlot' && item.timeSlot === ''){
        return;
      }
      setIsShowLoading(true)
      const dataSearch = {
        memberGroupType: item.memberGroupType,
        gender: item.gender,
        month: item.month,
        phoneNumbers: item.phoneNumbers,
        dayOfWeeks: item.dayOfWeeks,
        timeSlot: item.timeSlot,
      }
      const data = await countTotalMemberFollowGroup(dataSearch);
      const message = t('message.totalMemberWillBeSentThePushIs', {number: data.numberMember})
      setMessageReport(message)
      setIsShowLoading(false)
    }
  }

  useEffect(() => {
    fetchItem()
    fetchInitData()
  },[])

  useEffect(() => {
    fetchTotalMember()
  },[item.memberGroupType, item.month, item.phoneNumbers, item.dayOfWeeks, item.timeSlot, item.gender])

  const submitForm = async (eve) => {
    eve.preventDefault();
    setErrorMessage('');
    if (Number(item.contentType) === 1) {
      const resultValidate = validateLanguageObject(item[keyLangauge], keys)
      if (resultValidate){
        languageRef?.current?.handleActiveTab(resultValidate.indexTabLang)
        showErrorToast(`${t('requiredField', {field: resultValidate.key})}`)
        return;
      }
    } else {
      MULTIPLE_LANGUAGES.forEach(lang => {
        for(const key in item[keyLangauge][lang]){
          item[keyLangauge][lang][key] = ''
        }
      })
    }
    
    const data = convertViewToFormData(item, keys, keyLangauge, dataKeys, [], arrayKeys, [])
    const result = await saveReportPush(data)
    
    if(result.status) {
      showSuccessToast(t('push.reportPushCreatedSuccess'));
      fetchItem();
    } else {
      setErrorMessage(result?.data?.message || '');
    }
  }

  return (
    <>
      <PageTitle title={t('report.title')} />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        {errorMessage !== '' ? (<Grid item xs={12} style={{marginBottom: "20px"}}><Alert severity="error">{errorMessage}</Alert></Grid>): (<div></div>)}
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={5}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="memberGroupType"
                  defaultValue={'all'}
                  value={item.memberGroupType}
                  onChange={e => updateMemberGroupType(e.target.value)}
                >
                  {
                    Object.entries(types).map(([value, name]) => (
                      <div className={globalStyles.coverRadioItem} key={value}>
                        <FormControlLabel value={value} control={<Radio />} label={name} />
                        {
                          (value === PUSH_MEMBER_GROUP_TYPE.gender && item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.gender) && (
                          <div className={globalStyles.coverRadioSubItemContainer}>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="gender"
                              value={item.gender}
                              onChange={e => updateObjectValue('gender', e.target.value)}
                            >
                            {
                              Object.entries(genders).map(([value, name]) => (
                                <FormControlLabel key={value} value={value} control={<Radio />} label={name} />
                              ))
                            }
                            </RadioGroup>
                          </div>
                          )
                        }
                        {
                          ((value === PUSH_MEMBER_GROUP_TYPE.ghostCustomer && item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.ghostCustomer) || 
                          (value === PUSH_MEMBER_GROUP_TYPE.newMember && item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.newMember) || 
                          (value === PUSH_MEMBER_GROUP_TYPE.scanInvoice && item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.scanInvoice) || 
                          (value === PUSH_MEMBER_GROUP_TYPE.useCoupon && item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.useCoupon)) && (
                          <div className={globalStyles.coverRadioSubItemContainer}>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="month"
                              value={item.month}
                              onChange={e => updateObjectValue('month', e.target.value)}
                            >
                            {
                              months.map((item) => (
                                <FormControlLabel key={item.value} value={item.value} control={<Radio />} label={item.name} />
                              ))
                            }
                            </RadioGroup>
                          </div>
                          )
                        }
                        {
                          ((value === PUSH_MEMBER_GROUP_TYPE.endPhoneNumber && item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.endPhoneNumber)) && (
                            <div className={globalStyles.coverRadioSubItemContainer}>
                              {
                                Array.from(Array(10), (element, index)=> {
                                  return (<FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        checked={item.phoneNumbers.includes(index)}
                                        onChange={(e) => {
                                          updatePhoneNumbers(e.target.checked, index);
                                        }}
                                      />
                                    }
                                    label={index}
                                  />)
                                })
                              }
                            </div>
                          )
                        }
                        {
                          ((value === PUSH_MEMBER_GROUP_TYPE.scanInvoiceInDay && item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.scanInvoiceInDay)) && (
                            <div className={globalStyles.coverRadioSubItemContainer}>
                              {
                                Object.entries(weekdays).map(([value, name]) => (
                                  <FormControlLabel
                                    key={value}
                                    control={
                                      <Checkbox
                                        checked={item.dayOfWeeks.includes(value)}
                                        onChange={(e) => {
                                          updateDayOfWeek(e.target.checked, value);
                                        }}
                                      />
                                    }
                                    label={name}
                                  />
                                ))
                              }
                            </div>
                          )
                        }
                        {
                          (value === PUSH_MEMBER_GROUP_TYPE.scanInvoiceInTimeSlot && item.memberGroupType === PUSH_MEMBER_GROUP_TYPE.scanInvoiceInTimeSlot) && (
                          <div className={globalStyles.coverRadioSubItemContainer}>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="timeSlot"
                              value={item.timeSlot}
                              onChange={e => updateObjectValue('timeSlot', e.target.value)}
                            >
                            {
                              timeSlots.map((item) => (
                                <FormControlLabel key={item.code} value={item.code} control={<Radio />} label={item.name} />
                              ))
                            }
                            </RadioGroup>
                          </div>
                          )
                        }
                      </div>
                    ))
                  }
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={4}>
              <Grid item xs={12} style={{minHeight: '25px', fontSize: '20px'}}>
                <b>{messageReport}</b>
              </Grid>
              <Grid item xs={12}>
                <label>{t('push.contentType')}</label>
                <Select
                  required
                  displayEmpty
                  value={item.contentType || ''}
                  onChange={e => updateObjectValue('contentType', e.target.value)}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      classes={{
                        notchedOutline: globalStyles.mainChartSelectRoot,
                        input: globalStyles.mainChartSelect,
                      }}
                    />
                  }
                  fullWidth
                >
                  <MenuItem value="">{t('selectOne')} {t('push.contentType')}</MenuItem>
                  {Object.entries(contentTypes ?? {}).map((item) => (
                    <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                  ))}
                </Select>
              </Grid>
              {
                Number(item.contentType) === 2 && (
                  <Grid item xs={12} >
                    <label>{t('news.title')}</label>
                    <Select
                      required
                      displayEmpty
                      value={item.newsId || ''}
                      onChange={e => updateObjectValue('newsId', e.target.value) }
                      input={
                        <OutlinedInput
                          labelWidth={0}
                          classes={{
                            notchedOutline: globalStyles.mainChartSelectRoot,
                            input: globalStyles.mainChartSelect,
                          }}
                        />
                      }
                      fullWidth
                    >
                      <MenuItem value="">{t('selectOne')} {t('news.title')}</MenuItem>
                      {news.map((item) => (
                        <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )
              }
              {
                Number(item.contentType) === 3 && (
                  <Grid item xs={12} >
                    <label>{t('brandAds.title')}</label>
                    <Select
                      required
                      displayEmpty
                      value={item.adsId || ''}
                      onChange={e => updateObjectValue('adsId', e.target.value) }
                      input={
                        <OutlinedInput
                          labelWidth={0}
                          classes={{
                            notchedOutline: globalStyles.mainChartSelectRoot,
                            input: globalStyles.mainChartSelect,
                          }}
                        />
                      }
                      fullWidth
                    >
                      <MenuItem value="">{t('selectOne')} {t('brandAds.title')}</MenuItem>
                      {ads.map((item) => (
                        <MenuItem key={item._id} value={item._id}>{item.brandName} - {item.name}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )
              }
              {
                Number(item.contentType) === 1 && (
                  <Grid item xs={12}>
                    { 
                      item[keyLangauge] ? 
                      (<MultiLanguageTabs data={item[keyLangauge]} ref={languageRef} updateObjectValue={(data) => updateObjectValue(keyLangauge, data)} keys={keys}/>) : 
                      (<></>) 
                    }
                  </Grid>
                )
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" sx={{ marginRight: "1rem" }} type="submit">
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </>
  );
}

export default withRouter(PushForm);