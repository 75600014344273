import {
  Chip
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import useStyles from "./styles";

const StatusLabel = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('common');
    const { status } = props;
    const statusLabel = status === true ? t('active') : t('deactive') 
    const className = status === true ? 'success' : 'warning'
  
    return (
      <Chip label={statusLabel} classes={{root: classes[className]}}/>
    );
}

export default StatusLabel