import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TablePagination,
} from "@material-ui/core";
import TablePaginationActions from "../../../components/Table/TablePaginationActions";

const HightVisit = (props) => {
  const { tableData, page, rowsPerPage, updatePage } = props
  const viewData = (tableData || []).slice(page * rowsPerPage, (page + 1) * rowsPerPage)

  const handleChangePage = (event, newPage) => {
    updatePage(newPage);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">No.</TableCell>
            <TableCell align="left">Code</TableCell>
            <TableCell align="left">Total Amount&nbsp;</TableCell>
            <TableCell align="left">Count&nbsp;</TableCell>
            <TableCell align="left">Fullname&nbsp;</TableCell>
            <TableCell align="left">Phone&nbsp;</TableCell>
            <TableCell align="left">Email&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { 
            (Array.isArray(viewData) && viewData.length > 0) && viewData.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell align="left">{row._id}</TableCell>
                <TableCell align="left">{row.totalAmount}</TableCell>
                <TableCell align="left">{row.count}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.phone}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer>
  );
};
export default HightVisit;