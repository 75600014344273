import React from "react";

// styles
import useGlobalStyles from "../../styles";

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var globalClasses = useGlobalStyles();

  return (
    <div className={globalClasses.pageTitleContainer}>
      <Typography className={globalClasses.typo} variant="h1" size="sm">
        {props.title}
      </Typography>
      {props.button && props.button}
    </div>
  );
}
