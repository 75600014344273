import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TableHead,
  TextField,
  OutlinedInput
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress, Select, MenuItem } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import TablePaginationActions from "../../components/Table/TablePaginationActions"

// styles
import useGlobalStyles from "../../styles";
import { getCouponSelect, getMemberCoupons } from "../../utils/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDatepicker, formatDatepickerSumit, showDateHK, showDateUTC } from "../../utils/helpers";

function MemberCoupon(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { t } = useTranslation('common');
  const defaultPaging = {
    sortName: '',
    sortType: '',
    limitPerPage: 20,
    page: 0,
  }

  const [pagingData, setPagingData] = useState(defaultPaging);
  const [phone, setPhone] = useState('');
  const [coupon, setCoupon] = useState('');
  const [expiredFrom, setExpiredFrom] = useState(null);
  const [expiredTo, setExpiredTo] = useState(null);
  const [usedFrom, setUsedFrom] = useState(null);
  const [usedTo, setUsedTo] = useState(null);

  const [couponSelect, setCouponSelect] = useState([]);

  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isShowLoading, setIsShowLoading] = useState(false);


  const fetchList = async() => {
    setIsShowLoading(true);
    const data = { ...pagingData,
      page: pagingData.page + 1,
      phone,
      coupon,
      expiredFrom: expiredFrom ? formatDatepickerSumit(expiredFrom) : '',
      expiredTo: expiredTo ? formatDatepickerSumit(expiredTo) : '',
      usedFrom: usedFrom ? formatDatepickerSumit(usedFrom) : '',
      usedTo: usedTo ? formatDatepickerSumit(usedTo) : '',
    }

    const result = await getMemberCoupons(data);
    setTotalItems(result.totalItems ?? 0)
    setItems(result.itemList);
    setIsShowLoading(false);
  }

  const getData = async() => {
    const couponData = await getCouponSelect();
    setCouponSelect(couponData);
  }

  useEffect(() => {
    getData()
    fetchList();
  }, [pagingData])

  const handleChangePage = (event, newPage) => {
    const data = pagingData
    data.page = newPage
    setPagingData(data);
    fetchList();
  };

  const clearSearchData = () => {
    setPagingData(defaultPaging);
    setPhone('');
    setCoupon('');
    setExpiredFrom('');
    setExpiredTo('');
    setUsedFrom('');
    setUsedTo('');
  }

  const openDetail = (id) => {
    history.push(`/member-coupon/${id}`);
  }

  const submitForm = (eve) => {
    eve.preventDefault();
    const data = pagingData
    data.page = 0
    setPagingData(data);
    fetchList()
  }

  return (
    <>
      <PageTitle title={t('member.memberCoupon')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/member-coupon/add')}
        >
          { t('member.assignCoupon') }
        </Button>}/>
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={3}>
            <TextField
              label={t('phone')}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={phone}
              onChange={e => setPhone(e.target.value)}
              placeholder={t('phone')}
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <label>{t('coupon.title')}</label>
            <Select
              displayEmpty
              value={coupon}
              label={t('coupon.title')}
              onChange={e => setCoupon(e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {Object.keys(couponSelect).map((key) => (
                <MenuItem key={key} value={key}>{couponSelect[key]}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              label={t('member.expiredFrom')}
              value={expiredFrom}
              views={["year", "month", "day"]}
              onChange={e => setExpiredFrom(e)}
              renderInput={(params) => <TextField {...params} fullWidth inputProps={
                { 
                  ...params.inputsProps, 
                  value: expiredFrom ? formatDatepicker(expiredFrom) : '',
                  placeholder: t('member.expiredFrom')
                }
              }
            />}
            />
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              label={t('member.expiredTo')}
              value={expiredTo}
              views={["year", "month", "day"]}
              onChange={e => setExpiredTo(e)}
              renderInput={(params) => <TextField {...params} fullWidth inputProps={
                { 
                  ...params.inputsProps, 
                  value: expiredTo ? formatDatepicker(expiredTo) : '',
                }
              }/>}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={3}>
            <DatePicker
              label={t('member.usedFrom')}
              value={usedFrom}
              views={["year", "month", "day"]}
              onChange={e => setUsedFrom(e)}
              renderInput={(params) => <TextField {...params} fullWidth inputProps={
                { 
                  ...params.inputsProps, 
                  value: usedFrom ? formatDatepicker(usedFrom) : '',
                }
              }/>}
            />
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              label={t('member.usedTo')}
              value={usedTo}
              views={["year", "month", "day"]}
              onChange={e => setUsedTo(e)}
              renderInput={(params) => <TextField {...params} fullWidth inputProps={
                { 
                  ...params.inputsProps, 
                  value: usedTo ? formatDatepicker(usedTo) : '',
                }
              }/>}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end" style={{marginTop: "1rem"}}>
              <Button variant="contained" sx={{ marginRight: "1rem" }} type="submit">
                {t('search')}
              </Button>
              <Button variant="contained" color="inherit" onClick={() => clearSearchData()}>
                {t('clear')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('name')}
                  </TableCell>
                  <TableCell>
                    {t('phone')}
                  </TableCell>
                  <TableCell>
                    {t('coupon.title')}
                  </TableCell>
                  <TableCell>
                    {t('member.expiredDate')}
                  </TableCell>
                  <TableCell align="center">
                    {t('member.usedDate')}
                  </TableCell>
                  <TableCell>
                    {t('createdAt')}
                  </TableCell>
                  <TableCell >
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  items?.length > 0 ? 
                  items.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.member?.fullName}
                      </TableCell>
                      <TableCell>
                        {row.member?.phone}
                      </TableCell>
                      <TableCell>
                        {row.coupon?.name || ''}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        {showDateUTC(row.expiredDate)}
                      </TableCell>
                      <TableCell style={{ width: 160 }}>
                        {row?.usedDate ? showDateHK(row.usedDate) : ''}
                      </TableCell>
                      <TableCell style={{ width: 160 }}>
                        {showDateUTC(row.createdAt)}
                      </TableCell>
                      <TableCell style={{ width: 220 }} align="left">
                        <Button variant="contained" size="small" color="info" sx={{ marginRight: "1rem" }} onClick={() => openDetail(row._id)}>
                          {t('view')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )) : (
                    <TableRow >
                      <TableCell colSpan={8} >{ t('message.notAnyRecord') }</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={3}
                    count={totalItems}
                    rowsPerPage={pagingData.limitPerPage}
                    page={pagingData.page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(MemberCoupon);