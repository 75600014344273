import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Grid,
  TextField,
  OutlinedInput
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress, Select, MenuItem, Autocomplete,  } from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle";

// styles
import useGlobalStyles from "../../styles";
import { updateMemberPoint } from "../../utils/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showErrorToast } from "../../utils/helpers";

function MemberTransactionForm(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { t } = useTranslation('common');

  const actionOptions = ['plus', 'minus'];

  const [item, setItem] = useState([]);

  const fetchInitData = async () => {
    setIsShowLoading(true)
    setItem({
      phone: '',
      action: 'plus',
      quantity: 0,
      description: ''
    })
    setIsShowLoading(false)
  }

  useEffect(() => {
    fetchInitData()
  },[])

  const [isShowLoading, setIsShowLoading] = useState(false);

  // For member search autocomplete component

  const submitForm = async (eve) => {
    eve.preventDefault();
    const result = await updateMemberPoint(item)
    if (result.status){
      showErrorToast(result.data?.message ?? '')
      history.push('/member-transaction')
    } else {
      showErrorToast(result.message || '')
    }
  }

  const updateObjectValue = (key, value) => {
    setItem(eve => ({...eve, [key]: value}))
  }

  return (
    <>
      <PageTitle title={t('member.memberTransaction')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/member-transaction')}
        >
          { t('backToList') }
        </Button>} />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.phone }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.phone}
              onChange={e => { updateObjectValue('phone', e.target.value) }}
              label={t('phone')}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <label>{t('member.action')}</label>
            <Select
              value={item?.action ?? 'plus'}
              onChange={e => { updateObjectValue('action', e.target.value) }}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              margin="normal"
              fullWidth
            >
              {actionOptions.map((item, index) => (
                <MenuItem key={index} value={item}>{t(`member.${item}`)}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.quantity}
              onKeyDown={(value) => {
                const allowKey = ['0', '1','2','3','4','5','6','7','8','9','10', 'Backspace', 'Delete']
                if (!allowKey.includes(value.key)) value.preventDefault()
              }}
              onChange={e => { updateObjectValue('quantity', e.target.value) }}
              label={t('member.gCoin')}
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={9}>
            <TextField
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.description}
              onChange={e => { updateObjectValue('description', e.target.value) }}
              label={t('description')}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" sx={{ marginRight: "1rem" }} type="submit">
                {t('save')}
              </Button>
              <Button variant="contained" color="inherit" onClick={() => history.push('/member-transaction')}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </>
  );
}

export default withRouter(MemberTransactionForm);