import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TableHead,
  Select,
  OutlinedInput
} from "@material-ui/core";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Backdrop, CircularProgress, Box, MenuItem } from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import StatusLabel from "../../components/Table/StatusLabel";
import TablePaginationActions from "../../components/Table/TablePaginationActions"

// styles
import useGlobalStyles from "../../styles";
import { deleteBrandAds, getBrandAdsList, getBrands } from "../../utils/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showErrorToast } from "../../utils/helpers";

function BrandHotItem(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { t } = useTranslation('common');
  const defaultPaging = {
    sortName: '',
    sortType: '',
    limitPerPage: 20,
    page: 0,
  }

  const [pagingData, setPagingData] = useState(defaultPaging);
  const [brandId, setBrandId] = useState('');

  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentItem, setCurrentItem] = useState({});
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);


  const [brands, setBrands] = useState([]);

  const fetchList = async() => {
    setIsShowLoading(true);
    const data = { ...pagingData,
      page: pagingData.page + 1,
      brandId,
    }
    
    const result = await getBrandAdsList(data);
    setTotalItems(result.totalItems ?? 0)
    setItems(result.itemList);
    setIsShowLoading(false);
  }

  const getDataBrands = async () => {
    const dataBrands = await getBrands({isActive: true});
    setBrands(dataBrands?.itemList || [])
  }

  useEffect(() => {
    fetchList();
  }, [pagingData])

  useEffect(() => {
    getDataBrands();
  }, [])

  const handleChangePage = (event, newPage) => {
    const data = pagingData
    data.page = newPage
    setPagingData(data);
    fetchList();
  };

  const clearSearchData = () => {
    setPagingData(defaultPaging);
    setBrandId('');
  }

  const openDetail = (id) => {
    history.push(`/brand-ads/${id}`);
  }

  const submitForm = (eve) => {
    eve.preventDefault();
    const data = pagingData
    data.page = 0
    setPagingData(data);
    fetchList()
  }

  const openEdit = (id) => {
    history.push(`/brand-ads/${id}/edit`);
  }

  const deleteItem = (item) => {
    setCurrentItem(item)
    setIsOpenDialog(true)
  }

  const confirmDelete = async () => {
    let result = await deleteBrandAds(currentItem._id)
    if(result.status) {
      setIsOpenDialog(false)
      fetchList()
    } else {
      showErrorToast(result?.data?.message || '')
    }
  }

  return (
    <>
      <PageTitle title={t('brandAds.title')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/brand-ads/add')}
        >
          { t('create') }
        </Button>}/>
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={6}>
            <label>{t('brand.title')}</label>
            <Select
              displayEmpty
              value={brandId}
              onChange={e => setBrandId(e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {brands.map((item) => (
                <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" sx={{ marginRight: "1rem" }} type="submit">
                {t('search')}
              </Button>
              <Button variant="contained" color="inherit" onClick={() => clearSearchData()}>
                {t('clear')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('thumbnail')}
                  </TableCell>
                  <TableCell>
                    {t('name')}
                  </TableCell>
                  <TableCell>
                    {t('brand.title')}
                  </TableCell>
                  <TableCell align="center">
                    {t('status')}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  items?.length > 0 ? 
                  items.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        <Box
                          component="img"
                          sx={{
                            width: 200,
                          }}
                          alt={row.name}
                          src={row.imageUrl}
                        />
                      </TableCell>
                      <TableCell>
                        {row.campaignName}
                      </TableCell>
                      <TableCell>
                        {row.brand?.name}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="center">
                        <StatusLabel status={row.isActive}></StatusLabel>
                      </TableCell>
                      <TableCell style={{ width: 280 }} align="left" className={globalStyles.tableButton}>
                        <Button variant="contained" size="small" color="info" onClick={() => openDetail(row._id)}>
                          {t('view')}
                        </Button>
                        <Button variant="contained" size="small" color="primary" onClick={() => openEdit(row._id)}>
                          {t('edit')}
                        </Button>
                        <Button variant="contained" size="small" color="warning" onClick={() => deleteItem(row)}>
                        {t('delete')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )) : (
                    <TableRow >
                      <TableCell colSpan={9} >{ t('message.notAnyRecord') }</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={9}
                    count={totalItems}
                    rowsPerPage={pagingData.limitPerPage}
                    page={pagingData.page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          { t('warning') }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { 
              t('confirmDeleteItem', { 
                name: currentItem.campaignName
              })
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpenDialog(false)}>{t('cancel')}</Button>
          <Button onClick={confirmDelete} autoFocus>
            {t('continuous')}
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(BrandHotItem);