import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Select,
  OutlinedInput
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress, Alert, MenuItem, FormControlLabel, Checkbox} from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import useGlobalStyles from "../../styles";
import { createPartner, getPartner, updatePartner } from "../../utils/api";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { convertDataToViewMultilanguage, convertViewToDataMultilanguage, formatDatepicker, formatDatepickerSumit, setDefaultObject } from "../../utils/helpers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GENDER } from "../../utils/constant";

function PartnerForm(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation('common');
  const keys = [];
  const dataPostkeys = ['fullName', 'email', 'phoneNumber', 'gender', 'birthday', 'password', 'repassword', 'accountActive'];
  const keyLangauge = ''

  const [item, setItem] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    if(id){
      const itemData = await getPartner(id);
      const formatDataLanguage = convertDataToViewMultilanguage(itemData, keys, keyLangauge)
      formatDataLanguage.password = ''
      formatDataLanguage.repassword = ''
      setItem(formatDataLanguage);
    } else {
      const defaultData = setDefaultObject(dataPostkeys, keys, keyLangauge)
      defaultData.birthday = null
      setItem(defaultData)
    }
    setIsShowLoading(false);
  }

  const updateObjectValue = (key, value) => {
    setItem(eve => ({...eve, [key]: value}))
  }

  useEffect(() => {
    fetchItem()
  },[])

  const submitForm = async (eve) => {
    eve.preventDefault();
    const data = convertViewToDataMultilanguage(item, keys, keyLangauge, dataPostkeys)
    if (data.password !== data.repassword) {
      setErrorMessage('Password and Re-Password are not match')
      return false
    }
    if (data.birthday) {
      data.birthday = formatDatepickerSumit(item.birthday)
    }
    let result = {}
    if (id) {
      result = await updatePartner(id, data)
    } else {
      result = await createPartner(data)
    }
    if(result.status) {
      history.push('/partner')
    } else {
      setErrorMessage(result?.data?.message || '')
    }
  }

  return (
    <>
      <PageTitle title={t('partner.form')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/partner')}
        >
          { t('backToList') }
        </Button>} />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        {errorMessage !== '' ? (<Grid item xs={12} style={{marginBottom: "20px"}}><Alert severity="error">{errorMessage}</Alert></Grid>): (<div></div>)}
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.fullName }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.fullName || ''}
              onChange={e => { updateObjectValue('fullName', e.target.value) }}
              label={t('name')}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.email }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.email || ''}
              onChange={e => { updateObjectValue('email', e.target.value) }}
              label={t('email')}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.phoneNumber }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.phoneNumber}
              onChange={e => { updateObjectValue('phoneNumber', e.target.value) }}
              label={t('phone')}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <label>{t('gender')}</label>
            <Select
              displayEmpty
              value={item.gender || ''}
              onChange={e => updateObjectValue('gender', e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              <MenuItem value="">{t('selectOne')} {t('gender')}</MenuItem>
              {Object.keys(GENDER ?? {}).map((item) => (
                <MenuItem key={item} value={item}>{t(item)}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label={t('birthday')}
              value={item.birthday}
              views={["year", "month", "day"]}
              onChange={(e) => updateObjectValue("birthday", e)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  inputProps={{
                    ...params.inputsProps,
                    value: item.birthday
                      ? formatDatepicker(item.birthday)
                      : "",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.password }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              type="password"
              value={item.password || ''}
              onChange={e => { updateObjectValue('password', e.target.value) }}
              label={t('password')}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.repassword }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              type="password"
              value={item.repassword || ''}
              onChange={e => { updateObjectValue('repassword', e.target.value) }}
              label={t('repassword')}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel control={<Checkbox 
                checked={item.accountActive || false}
                onChange={e => { updateObjectValue('accountActive', e.target.checked) }} 
              />} label={t('IsActive')} />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" sx={{ marginRight: "1rem" }} type="submit">
                {t('save')}
              </Button>
              <Button variant="contained" color="inherit" onClick={() => history.push('/partner')}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </>
  );
}

export default withRouter(PartnerForm);