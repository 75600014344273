import React, { useState, useEffect } from 'react';
import { Button} from "@mui/material";
import { useTranslation } from "react-i18next";
import { showErrorToast } from '../../utils/helpers';

const ImageUploader = props => {
  const hiddenFileInput = React.useRef(null);
  const { t } = useTranslation('common');

  const [imageName, setImageName] = useState('');
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded.size >= 500 * 1000){
      showErrorToast('Please select the image that\'s size is smaller than 500KB');
      event.target.value = null;
      return false;
    }
    setImageName(fileUploaded.name)
    props.updateObjectValue(fileUploaded)
  };

  return (
    <>
      <div style={{display: "flex"}}>
        <Button
            variant="contained"
            size="medium"
            color="info"
            onClick={handleClick}
            sx={{width: 200, display: "flex", marginRight: 2}}
          >
            { t('uploadFile') }
        </Button>
        <div style={{display: "flex", flexGrow: 1}}>
          { imageName !== '' ? imageName : props.value }
        </div>
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}} 
        accept="image/*"
      />
    </>
  );
}
export default ImageUploader;