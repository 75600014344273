import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import {
  Grid,
  Box,
} from "@material-ui/core";

// styles
import useGlobalStyles from "../../styles";
import { useHistory, useParams } from "react-router-dom";
import { getCoupon } from "../../utils/api";
import DetailRow from "../../components/Table/DetailRow";
import DetailHtmlRow from "../../components/Table/DetailHtmlRow";
import MultiLanguageDetailRow from '../../components/Table/MultiLanguageDetailRow';
import DetailImageRow from "../../components/Table/DetailImageRow";
import { useTranslation } from "react-i18next";
import { showDateUTC } from "../../utils/helpers";

function CouponDetail(props) {
  var globalClasses = useGlobalStyles();
  const { t } = useTranslation('common');
  const history = useHistory();
  const { id } = useParams();
  const [item, setItem] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    const result = await getCoupon(id);
    setItem(result);
    setIsShowLoading(false);
  }

  useEffect(() => {
    fetchItem()
  }, [])

  return (
    <>
      <PageTitle title={t('coupon.detail')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/coupon')}
        >
          { t('backToList') }
        </Button>}
      />
      <Box style={{marginBottom: "3rem"}}>
        <Grid className={globalClasses.containerDetail}>
          <DetailRow label={t('id')} value={item._id}/>
          <DetailImageRow label={t('thumbnail')} value={item.imageUrl}/>
          <DetailRow label={t('brand.title')} value={item.brand?.name || ''}/>
          <DetailRow label={t('quantity')} value={item.quantity}/>
          <DetailRow label={t('publishDate')}  value={showDateUTC(item.publicDate)}/>
          <MultiLanguageDetailRow label={t('name')} value={item.name}/>
          <MultiLanguageDetailRow label={t('description')} value={item.description}/>
          {
            item.privatedTermHtml ? (
              <DetailHtmlRow label={t('privatedTerm')} value={item.privatedTermHtml}/>
            ):(
              <DetailRow label={t('privatedTerm')} value={item.privatedTerm}/>
            )
          }
          <DetailRow label={t('member.gCoin')} value={item.gCoinPrice}/>
          <DetailRow label={t('coupon.numOfExchange')} value={item.numOfExchange}/>
          <DetailRow label={t('coupon.expiredType')} value={item.expired?.expiredType ? t(`coupon.${item.expired?.expiredType}`) : ''}/>
          <DetailRow label={t('coupon.expiredDate')} value={item.expiredDate ? showDateUTC(item.expiredDate): ''}/>
          <DetailRow label={t('coupon.expiredRange')} value={item.expired?.expiredRange || ''}/>
          <DetailRow label={t('isRecommend')} value={item.isRecommend ? 'Yes' : 'No'}/>
          <DetailRow label={t('isGift')} value={item.isMemberCoupon ? 'Yes' : 'No'}/>
          <DetailRow label={t('isActive')} value={item.isActive ? 'Yes' : 'No'}/>
          <DetailRow label={t('createdAt')} value={showDateUTC(item.createdAt) }/>
          <DetailRow label={t('updatedAt')} value={showDateUTC(item.updatedAt) } isNoneBorder={true}/>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default withRouter(CouponDetail);
