import { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";

function DownloadApp(props) {
  const windowsPhone = "Windows Phone";
  const android = "Android";
  const ios = "iOS";
  const history = useHistory();

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return windowsPhone;
    }

    if (/android/i.test(userAgent)) {
      return android;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return ios;
    }

    return "unknown";
  }

  useEffect(() => {
    const currentOs = getMobileOperatingSystem();
    switch(currentOs){
      case android:
        location.href = "https://play.google.com/store/apps/details?id=com.globallink.app.prd";
        break;
      case ios:
        location.href = "https://apps.apple.com/hk/app/global-link-dinning-club/id1600416323";
        break;
      default:
        history.push('/error')
        break;
    }
  }, [])

  return <></>
}

export default withRouter(DownloadApp);
