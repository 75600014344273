import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  Select,
  OutlinedInput
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress, Alert, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import useGlobalStyles from "../../styles";
import {  getCoupon, updateCoupon, createCoupon, getBrands } from "../../utils/api";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { convertDataToViewMultilanguage, convertViewToFormData, formatDatepicker, formatDatepickerSumit, setDefaultObject, validateLanguageObject } from "../../utils/helpers";
import MultiLanguageTabs from "../../components/Common/MultiLanguageTabs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ImageUploader from "../../components/Common/ImageUploader";
import CKEditor from 'ckeditor4-react';

function CouponForm(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation('common');
  const keys = ['name', 'description'];
  const dataKeys = ['name', 'description', 'publicDate', 'unpublicDate', 'isRecommend', 'brandId', 
    'isMemberCoupon', 'gCoinPrice', 'quantity', 'privatedTerm', 'privatedTermHtml', 'isActive'];
  const arrayObject = ['expired'];
  const filekeys = ['image'];
  const dataFormkeys = [...dataKeys, ...['logoUrl', 'bannerUrl']];
  const keyLangauge = 'couponLang'

  const expiredTypes = ['one_day', 'range']

  const [item, setItem] = useState([]);
  const [brands, setBrands] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    if(id){
      const itemData = await getCoupon(id);
      itemData.brandId = itemData?.brand?._id
      delete itemData.brand
      itemData.privatedTermHtml = itemData.privatedTermHtml ? itemData.privatedTermHtml : itemData.privatedTerm
      const formatDataLanguage = convertDataToViewMultilanguage(itemData, keys, keyLangauge)
      setItem(formatDataLanguage);
    } else {
      const defaultData = setDefaultObject(dataFormkeys, keys, keyLangauge)
      defaultData.expired = {
        expiredType: 'one_day',
        expiredDate: null,
        expiredRange: ''
      }
      defaultData.publicDate = null;
      defaultData.unpublicDate = null;
      setItem(defaultData)
    }
    setIsShowLoading(false);
  }

  const fetchInitData = async () => {
    const brands = await getBrands({isActive: true});
    if(brands?.itemList) {
      setBrands(brands.itemList);
    }
  }

  const updateObjectValue = (key, value) => {
    setItem(eve => ({...eve, [key]: value}))
  }

  const updateObjectValueSubKey = (key, subKey, value) => {
    setItem(eve => ({...eve, [key]: {...eve[key], [subKey]: value}}))
  }

  useEffect(() => {
    fetchItem()
    fetchInitData()
  },[])

  const languageRef = useRef();

  const submitForm = async (eve) => {
    eve.preventDefault();

    const resultValidate = validateLanguageObject(item[keyLangauge], keys)
    if (resultValidate){
      languageRef?.current?.handleActiveTab(resultValidate.indexTabLang)
      return;
    }
    
    const cloneItem = { ...item, 
      publicDate: item.publicDate ? formatDatepickerSumit(item.publicDate): null, 
      unpublicDate: item.unpublicDate ? formatDatepickerSumit(item.unpublicDate): null
    }
    if(cloneItem.expired?.expiredDate){
      cloneItem.expired.expiredDate = cloneItem.expired.expiredDate ? formatDatepickerSumit(cloneItem.expired.expiredDate) : null
    }else{
      delete cloneItem.expired.expiredDate
    }
    const data = convertViewToFormData(cloneItem, keys, keyLangauge, dataKeys, filekeys, [], arrayObject)
    let result = {}
    if (id) {
      result = await updateCoupon(id, data)
    } else {
      result = await createCoupon(data)
    }
    if(result.status) {
      history.push('/coupon')
    } else {
      setErrorMessage(result?.data?.message || '')
    }
  }

  return (
    <>
      <PageTitle title={t('coupon.form')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/coupon')}
        >
          { t('backToList') }
        </Button>} />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        {errorMessage !== '' ? (<Grid item xs={12} style={{marginBottom: "20px"}}><Alert severity="error">{errorMessage}</Alert></Grid>): (<div></div>)}
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={6}>
            <label>{t('brand.title')}</label>
            <Select
              displayEmpty
              value={item.brandId || ''}
              onChange={e => updateObjectValue('brandId', e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              <MenuItem value="">{t('selectOne')} {t('brand.title')}</MenuItem>
              {brands.map((item) => (
                <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12}>
            { 
              item[keyLangauge] ? 
              (<MultiLanguageTabs data={item[keyLangauge]} ref={languageRef} updateObjectValue={(data) => updateObjectValue(keyLangauge, data)} keys={keys}/>) : 
              (<></>) 
            }
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.gCoinPrice }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.gCoinPrice}
              onChange={e => { updateObjectValue('gCoinPrice', e.target.value) }}
              label={t('gCoin')}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.quantity }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.quantity}
              onChange={e => { updateObjectValue('quantity', e.target.value) }}
              label={t('quantity')}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <label>{t('coupon.expiredType')}</label>
            <Select
              displayEmpty
              value={item.expired?.expiredType || ''}
              onChange={e => updateObjectValueSubKey('expired', 'expiredType', e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              {expiredTypes.map((item) => (
                <MenuItem key={item} value={item}>{t(`coupon.${item}`)}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            {
              item.expired?.expiredType === 'one_day' ? 
              (
                <DatePicker
                  label={t('coupon.expiredDate')}
                  value={item.expired?.expiredDate}
                  views={["year", "month", "day"]}
                  onChange={e => updateObjectValueSubKey('expired', 'expiredDate', e)}
                  renderInput={(params) => <TextField 
                    required {...params} fullWidth inputProps={
                    { 
                      ...params.inputsProps, 
                      value: item.expired?.expiredDate ? formatDatepicker(item.expired.expiredDate) : '',
                    }
                  }
                />}
                />
              ) : (
                <TextField
                  required
                  InputLabelProps={{ shrink: !!item.expired?.expiredRange }}
                  InputProps={{
                    classes: {
                      underline: globalStyles.textFieldUnderline,
                      input: globalStyles.textField,
                    },
                  }}
                  value={item.expired?.expiredRange || ''}
                  onChange={e => { updateObjectValueSubKey('expired', 'expiredRange', e.target.value) }}
                  label={t('coupon.expiredRange')}
                  fullWidth
                />
              )
            }
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label={t('publishDate')}
              value={item.publicDate}
              views={["year", "month", "day"]}
              onChange={e => updateObjectValue('publicDate', e)}
              renderInput={(params) => <TextField {...params} fullWidth inputProps={
                  { 
                    ...params.inputsProps, 
                    value: item.publicDate ? formatDatepicker(item.publicDate) : '',
                  }
                }
              />}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label={t('unpublishDate')}
              value={item.unpublicDate}
              views={["year", "month", "day"]}
              onChange={e => updateObjectValue('unpublicDate', e)}
              renderInput={(params) => <TextField {...params} fullWidth inputProps={
                  { 
                    ...params.inputsProps, 
                    value: item.unpublicDate ? formatDatepicker(item.unpublicDate) : '',
                  }
                }
              />}
            />
          </Grid>
          <Grid item xs={6}>
            <ImageUploader value={item.imageUrl} updateObjectValue={(data) => updateObjectValue('image', data)} />
          </Grid>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={12}>
            <CKEditor
              data={item.privatedTermHtml}
              onChange={({editor}) => {
                updateObjectValue('privatedTermHtml', editor.getData())
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel control={<Checkbox 
                checked={item.isMemberCoupon || false}
                onChange={e => { updateObjectValue('isMemberCoupon', e.target.checked) }} 
              />} label={t('isGift')} />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel control={<Checkbox 
                checked={item.isRecommend || false}
                onChange={e => { updateObjectValue('isRecommend', e.target.checked) }} 
              />} label={t('isRecommend')} />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel control={<Checkbox 
                checked={item.isActive || false}
                onChange={e => { updateObjectValue('isActive', e.target.checked) }} 
              />} label={t('IsActive')} />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" sx={{ marginRight: "1rem" }} type="submit">
                {t('save')}
              </Button>
              <Button variant="contained" color="inherit" onClick={() => history.push('/coupon')}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </>
  );
}

export default withRouter(CouponForm);