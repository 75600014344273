import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Select,
  OutlinedInput
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress, Alert, MenuItem, FormControlLabel, Checkbox} from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import useGlobalStyles from "../../styles";
import { getPointSchemeTypes, getBrandLocationData, getTierData, updatePointScheme, createPointScheme, getWeekday, getPointScheme } from "../../utils/api";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDatetimePicker, formatDatetimePickerSubmit, formatResponseDate, setDefaultObject } from "../../utils/helpers";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import InputMaskTime from '../../components/Common/InputMaskTime'

function PointSchemeForm(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation('common');
  const dataKeys = ['typeId', 'shopId', 'tierId', 'startDate', 'endDate', 'weekday', 'day', 
    'startTime', 'endTime', 'ratio', 'isActive'];

  const [item, setItem] = useState({});
  const [types, setTypes] = useState({});
  const [shops, setShops] = useState([]);
  const [tiers, setTiers] = useState({});
  const [weekdays, setWeekdays] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    if(id){
      const itemData = await getPointScheme(id);
      if(itemData){
        itemData['shopId'] = itemData?.shop?._id ?? ''
        delete itemData.shop
        item.startDate = formatResponseDate(item.startDate)
        item.endDate = formatResponseDate(item.endDate)
        setItem(itemData);
      }
    } else {
      const defaultData = setDefaultObject(dataKeys, [], '');
      defaultData.startDate = null;
      defaultData.endDate = null;
      setItem(defaultData)
    }
    setIsShowLoading(false);
  }

  const updateObjectValue = (key, value) => {
    setItem(eve => ({...eve, [key]: value}))
  }

  const fetchInitData = async () => {
    const pointSchemeTypes = await getPointSchemeTypes()
    setTypes(pointSchemeTypes ?? {})
    const brandLocationData = await getBrandLocationData({})
    setShops(brandLocationData ?? [])
    const tierData = await getTierData()
    setTiers(tierData ?? {})
    const weekdayData = await getWeekday()
    setWeekdays(weekdayData ?? {})
  }

  useEffect(() => {
    fetchItem()
    fetchInitData()
  },[])

  const submitForm = async (eve) => {
    eve.preventDefault();
    let result = {}
    const cloneItem = {...item, 
      startDate: item.startDate ? formatDatetimePickerSubmit(item.startDate) : '',
      endDate: item.endDate ? formatDatetimePickerSubmit(item.endDate) : '',
    }
    if (id) {
      result = await updatePointScheme(id, cloneItem)
    } else {
      result = await createPointScheme(cloneItem)
    }
    if(result.status) {
      history.push('/point-scheme')
    } else {
      setErrorMessage(result?.data?.message || '')
    }
  }

  return (
    <>
      <PageTitle title={t('pointScheme.form')} button={
        <Button
          variant="contained"
          size="medium"
          color="inherit"
          onClick={()=> history.push('/point-scheme')}
        >
          { t('backToList') }
        </Button>} />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        {errorMessage !== '' ? (<Grid item xs={12} style={{marginBottom: "20px"}}><Alert severity="error">{errorMessage}</Alert></Grid>): (<div></div>)}
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={6}>
            <label>{t('type')}</label>
            <Select
              displayEmpty
              value={item.typeId || ''}
              onChange={e => updateObjectValue('typeId', e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              <MenuItem value="">{t('selectOne')} {t('type')}</MenuItem>
              {Object.entries(types ?? {}).map((item, key) => (
                <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <DateTimePicker
              label={t('pointScheme.startDate')}
              value={item.startDate}
              // views={["year", "month", "day"]}
              onChange={e => updateObjectValue('startDate', e)}
              renderInput={(params) => <TextField {...params} fullWidth inputProps={
                { 
                  ...params.inputsProps, 
                  value: item.startDate ? formatDatetimePicker(item.startDate) : '',
                }
              }
            />}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker
              label={t('pointScheme.endDate')}
              value={item.endDate}
              // views={["year", "month", "day"]}
              onChange={e => updateObjectValue('endDate', e)}
              renderInput={(params) => <TextField {...params} fullWidth inputProps={
                { 
                  ...params.inputsProps, 
                  value: item.endDate ? formatDatetimePicker(item.endDate) : '',
                }
              }
            />}
            />
          </Grid>
          {
            (Number(item.typeId) === 1) && (
              <>
                <Grid item xs={6}>
                  <label>{t('pointScheme.shop')}</label>
                  <Select
                    displayEmpty
                    value={item.shopId || ''}
                    onChange={e => updateObjectValue('shopId', e.target.value)}
                    input={
                      <OutlinedInput
                        labelWidth={0}
                        classes={{
                          notchedOutline: globalStyles.mainChartSelectRoot,
                          input: globalStyles.mainChartSelect,
                        }}
                      />
                    }
                    fullWidth
                  >
                    <MenuItem value="">{t('selectOne')} {t('pointScheme.shop')}</MenuItem>
                    {shops.map((item) => (
                      <MenuItem key={item._id} value={item._id}>{item.brandName} - {item.code}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}></Grid>
              </>
            )
          }
          {
            (Number(item.typeId) === 2) && (
              <>
                <Grid item xs={6}>
                  <label>{t('pointScheme.tier')}</label>
                  <Select
                    displayEmpty
                    value={item.tierId || ''}
                    onChange={e => updateObjectValue('tierId', e.target.value)}
                    input={
                      <OutlinedInput
                        labelWidth={0}
                        classes={{
                          notchedOutline: globalStyles.mainChartSelectRoot,
                          input: globalStyles.mainChartSelect,
                        }}
                      />
                    }
                    fullWidth
                  >
                    <MenuItem value="">{t('selectOne')} {t('pointScheme.tier')}</MenuItem>
                    {Object.entries(tiers ?? {}).map((item, key) => (
                      <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}></Grid>
              </>
            )
          }
          {
            (Number(item.typeId) === 3) && (
              <>
                <Grid item xs={6}>
                  <label>{t('pointScheme.weekday')}</label>
                  <Select
                    displayEmpty
                    value={item.weekday || ''}
                    onChange={e => updateObjectValue('weekday', e.target.value)}
                    input={
                      <OutlinedInput
                        labelWidth={0}
                        classes={{
                          notchedOutline: globalStyles.mainChartSelectRoot,
                          input: globalStyles.mainChartSelect,
                        }}
                      />
                    }
                    fullWidth
                  >
                    <MenuItem value="">{t('selectOne')} {t('pointScheme.weekday')}</MenuItem>
                    {Object.entries(weekdays ?? {}).map((item, key) => (
                      <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}></Grid>
              </>
            )
          }
          {
            (Number(item.typeId) === 4) && (
              <>
                <Grid item xs={6}>
                  <label>{t('pointScheme.day')}</label>
                  <Select
                    displayEmpty
                    value={item.day || ''}
                    onChange={e => updateObjectValue('day', e.target.value)}
                    input={
                      <OutlinedInput
                        labelWidth={0}
                        classes={{
                          notchedOutline: globalStyles.mainChartSelectRoot,
                          input: globalStyles.mainChartSelect,
                        }}
                      />
                    }
                    fullWidth
                  >
                    <MenuItem value="">{t('selectOne')} {t('pointScheme.day')}</MenuItem>
                    {Array.from(Array(31), (element, index)=> {
                      return <MenuItem key={index} value={index + 1}>{(index + 1).toString().padStart(2, '0')}</MenuItem>
                    })}
                  </Select>
                </Grid>
              </>
            )
          }
          {
            (Number(item.typeId) === 5) && (
              <>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: !!item.startTime }}
                    InputProps={{
                      classes: {
                        underline: globalStyles.textFieldUnderline,
                        input: globalStyles.textField,
                      },
                      inputComponent: InputMaskTime,
                    }}
                    name="startTime"
                    value={item.startTime}
                    onChange={e => { updateObjectValue('startTime', e.target.value) }}
                    label={t('pointScheme.startTime')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{ shrink: !!item.endTime }}
                    InputProps={{
                      classes: {
                        underline: globalStyles.textFieldUnderline,
                        input: globalStyles.textField,
                      },
                      inputComponent: InputMaskTime,
                    }}
                    name="endTime"
                    value={item.endTime}
                    onChange={e => { updateObjectValue('endTime', e.target.value) }}
                    label={t('pointScheme.endTime')}
                    fullWidth
                  />
                </Grid>
              </>
            )
          }
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.ratio }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.ratio || ''}
              onChange={e => { updateObjectValue('ratio', e.target.value) }}
              label={`${t('pointScheme.ratio')}`}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}><label>*Note: {t('pointScheme.ratioWarning')}</label></Grid>
          <Grid item xs={6}>
            <FormControlLabel control={<Checkbox 
                checked={item.isActive || false}
                onChange={e => { updateObjectValue('isActive', e.target.checked) }} 
              />} label={t('IsActive')} />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" sx={{ marginRight: "1rem" }} type="submit">
                {t('save')}
              </Button>
              <Button variant="contained" color="inherit" onClick={() => history.push('/point-scheme')}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </>
  );
}

export default withRouter(PointSchemeForm);