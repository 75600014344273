import { Grid } from "@material-ui/core";
import { Typography } from "../Wrappers/Wrappers";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import useStyles from "./styles";

const MultiLanguageDetailRow = (props) => {
    const classes = useStyles();
    const { label, value, isNoneBorder } = props;
    const { t } = useTranslation("common");

    return (
        <Grid style={{ borderBottom: isNoneBorder ? "none" : "1px solid #999" }}>
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <Typography variant="h6" color="info" style={{ padding: "0.5rem 1rem" }}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    {Object.entries(value || {}).map(([key, value]) => (
                        <Box sx={{ display: "flex", p: 1, m: 1 }} key={key}>
                            <div style={{ marginRight: "20px" }}>{t(key)}: </div>
                            <div>{value}</div>
                        </Box>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MultiLanguageDetailRow;
