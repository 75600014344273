import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  Event as NewsIcon,
  ArrowBack as ArrowBackIcon,
  GroupWork as GroupWorkIcon,
  Restaurant as BrandIcon,
  SettingsApplications as ConfigIcon,
  Policy as PolicyIcon,
  LocationOn as BrandLocationIcon,
  CardGiftcard as CouponIcon,
  People as MemberIcon,
  Extension as AdsCampaignIcon,
  Receipt as MemberTransactionIcon,
  Notifications as NotificationsIcon,
  Assessment as AssessmentIcon
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import clsx from 'clsx';
// styles
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { ADMIN_ROLE } from "../../utils/constant";


function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  const { t } = useTranslation('common');
  const structure = [
    { id: 1, label: "Dashboard", link: "/dashboard", icon: <HomeIcon /> },
    { id: 2, type: "divider" },
    { id: 21, label: t('brandGroup.title'), link: "/brand-group", icon: <GroupWorkIcon /> },
    { id: 22, label: "Brands", link: "/brand", icon: <BrandLocationIcon />},
    { id: 23, label: "Hot Items", link: "/brand-item", icon: <BrandIcon />},
    { id: 24, label: "Ads Campaigns", link: "/brand-ads", icon: <AdsCampaignIcon />},
    { id: 25, label: t('brandLocation.title'), link: "/brand-location", icon: <BrandLocationIcon />},
    { id: 26, label: "News", link: "/news", icon: <NewsIcon /> },
    // { id: 27, label: "Reports", link: "/report", icon: <AssessmentIcon /> },
    { id: 3, type: "divider" },
    { id: 31, label: "Coupons", link: "/coupon", icon: <CouponIcon />},
    { id: 4, type: "divider" },
    { id: 41, label: "Members", link: "/member", icon: <MemberIcon /> },
    { id: 42, label: "Member Transactions", link: "/member-transaction", icon: <MemberTransactionIcon /> },
    { id: 43, label: "Member Coupons", link: "/member-coupon", icon: <CouponIcon /> },
    { id: 44, label: "Point Scheme", link: "/point-scheme", icon: <ConfigIcon /> },
    { id: 45, label: "Push", link: "/push", icon: <NotificationsIcon /> },
    { id: 46, label: "Report", link: "/report", icon: <AssessmentIcon /> },
    { id: 47, label: "Staff", link: "/staff", icon: <MemberIcon /> },
    { id: 48, label: "Partners", link: "/partner", icon: <MemberIcon /> },
    { id: 49, label: "Old Member", link: "/old-member", icon: <MemberIcon /> },
    { id: 5, type: "divider" },
    // { id: 51, label: "App Configs", link: "/config", icon: <ConfigIcon /> },
    { id: 52, label: "Policies", link: "/policy", icon: <PolicyIcon /> },
    { id: 53, label: "Tier", link: "/tier", icon: <ConfigIcon /> },
  ];
  

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  const strAdmin = localStorage.getItem('admin_detail')
  const adminDetail = JSON.parse(strAdmin)
  if (adminDetail.role === ADMIN_ROLE.supperAdmin){
    const exist = structure.filter(item => item.id === 21)
    if (exist.length === 0) {
      structure.push({ id: 53, label: "Administrator", link: "/admin", icon: <MemberIcon /> })
    }
  }

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={clsx(classes.sidebarList, classes.sidebarCustom)}>
        {structure.map(link => (
          <li key={link.id}>
            <SidebarLink
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          </li>
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
