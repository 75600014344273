import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import Themes from "./themes";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider }  from "./context/UserContext";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "./locale/en/common.json";
import common_zh from "./locale/zh/common.json";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
        common: common_en
    },
    zh: {
        common: common_zh
    },
  },
});

ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={Themes}>
              <I18nextProvider i18n={i18next}>
                <CssBaseline />
                <App />
              </I18nextProvider>
            </ThemeProvider>
        </LocalizationProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById('root')
);

