import React, { useState, useEffect, useRef } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
    TableHead,
    TextField,
} from "@material-ui/core";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import StatusLabel from "../../components/Table/StatusLabel";
import TablePaginationActions from "../../components/Table/TablePaginationActions";

// styles
import useStyles from "./styles";
import useGlobalStyles from "../../styles";
import { changeStatusMember, getMembers, getExportMembers } from "../../utils/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Member(props) {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const classes = useStyles();

    const downloadButton = useRef();
    const globalStyles = useGlobalStyles();
    const history = useHistory();
    const { t } = useTranslation("common");
    const defaultPaging = {
        sortName: "",
        sortType: "",
        limitPerPage: 20,
        page: 0,
    };

    const [pagingData, setPagingData] = useState(defaultPaging);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [spendingFrom, setSpendingFrom] = useState("");
    const [spendingTo, setSpendingTo] = useState("");
    const [gcoinFrom, setGcoinFrom] = useState("");
    const [gcoinTo, setGcoinTo] = useState("");

    const [items, setItems] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isShowLoading, setIsShowLoading] = useState(false);
    const [currentMember, setCurrentMember] = useState({});

    const fetchList = async () => {
        setIsShowLoading(true);
        const data = {
            ...pagingData,
            page: pagingData.page + 1,
            email,
            phone,
            spendingFrom,
            spendingTo,
            gcoinFrom,
            gcoinTo,
        };

        const result = await getMembers(data);

        setTotalItems(result.totalItems ?? 0);
        setItems(result.itemList);
        setIsShowLoading(false);
    };

    useEffect(() => {
        fetchList();
    }, [pagingData]);

    const handleChangePage = (event, newPage) => {
        const data = pagingData;
        data.page = newPage;
        setPagingData(data);
        fetchList();
    };

    const clearSearchData = () => {
        setPagingData(defaultPaging);
        setEmail("");
        setPhone("");
        setSpendingFrom("");
        setSpendingTo("");
        setGcoinFrom("");
        setGcoinTo("");
    };

    const openDetail = (id) => {
        history.push(`/member/${id}`);
    };

    const changeStatusItem = (member) => {
        setCurrentMember(member);
        setIsOpenDialog(true);
    };

    const submitForm = (eve) => {
        eve.preventDefault();
        const data = pagingData;
        data.page = 0;
        setPagingData(data);
        fetchList();
    };

    const handleClose = () => {
        setIsOpenDialog(false);
    };

    const confirmChangeStatus = async () => {
        await changeStatusMember(currentMember._id, currentMember.isEnabled);
        fetchList();
        setIsOpenDialog(false);
    };

    // const handleExportExcel = async () => {
    //     exportFile("xlsx");
    // };

    const handleExportCSV = async () => {
        const dataSearch = {
            email,
            phone,
            spendingFrom,
            spendingTo,
            gcoinFrom,
            gcoinTo,
        };
        setIsShowLoading(true);
        const response = await getExportMembers(dataSearch);

        if (response?.url) {
            downloadButton.current.href = response.url;
            downloadButton.current.click();
        } else {
            setErrorMessage("Export SCV has error. Please contact administrator");
        }
        setTimeout(() => {
            setIsShowLoading(false);
        }, 200);
    };

    return (
        <>
            <PageTitle title={t("member.title")} />
            <form className={globalStyles.fullWidth} onSubmit={submitForm}>
                <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
                    <Grid item xs={3}>
                        <TextField
                            InputProps={{
                                classes: {
                                    underline: globalStyles.textFieldUnderline,
                                    input: globalStyles.textField,
                                },
                            }}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder={t("phone")}
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            InputProps={{
                                classes: {
                                    underline: globalStyles.textFieldUnderline,
                                    input: globalStyles.textField,
                                },
                            }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={t("email")}
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            InputProps={{
                                classes: {
                                    underline: globalStyles.textFieldUnderline,
                                    input: globalStyles.textField,
                                },
                            }}
                            value={spendingFrom}
                            onChange={(e) => setSpendingFrom(e.target.value)}
                            placeholder={t("member.spendingFrom")}
                            type="number"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            InputProps={{
                                classes: {
                                    underline: globalStyles.textFieldUnderline,
                                    input: globalStyles.textField,
                                },
                            }}
                            value={spendingTo}
                            onChange={(e) => setSpendingTo(e.target.value)}
                            placeholder={t("member.spendingTo")}
                            type="number"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
                    <Grid item xs={3}>
                        <TextField
                            InputProps={{
                                classes: {
                                    underline: globalStyles.textFieldUnderline,
                                    input: globalStyles.textField,
                                },
                            }}
                            value={gcoinFrom}
                            onChange={(e) => setGcoinFrom(e.target.value)}
                            placeholder={t("member.gcoinForm")}
                            type="number"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            InputProps={{
                                classes: {
                                    underline: globalStyles.textFieldUnderline,
                                    input: globalStyles.textField,
                                },
                            }}
                            value={gcoinTo}
                            onChange={(e) => setGcoinTo(e.target.value)}
                            placeholder={t("member.gcoinTo")}
                            type="number"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justifyContent="flex-end">
                            <div style={{ marginBottom: "1rem" }}>
                                <a
                                    ref={downloadButton}
                                    download=""
                                    style={{ display: "hidden" }}
                                ></a>
                                <Button
                                    sx={{ minWidth: "90px", marginRight: "10px" }}
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleExportCSV}
                                >
                                    <div className={classes.exportWrapper}>
                                        <div>CSV</div>
                                    </div>
                                </Button>
                                {/* <Button
                  sx={{ minWidth: "90px", marginRight: '10px' }}
                  variant="contained"
                  color="info"
                  onClick={handleExportExcel}
                >
                  <div className={classes.exportWrapper}>
                    <div>EXEL</div>
                  </div>
                </Button> */}
                            </div>
                            <div>
                                <Button
                                    sx={{ minWidth: "90px", marginRight: "10px" }}
                                    variant="contained"
                                    type="submit"
                                >
                                    {t("search")}
                                </Button>
                                <Button
                                    sx={{ minWidth: "90px", marginRight: "10px" }}
                                    variant="contained"
                                    color="inherit"
                                    onClick={() => clearSearchData()}
                                >
                                    {t("clear")}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 500 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("ID")}</TableCell>
                                    <TableCell>{t("name")}</TableCell>
                                    <TableCell>{t("phone")}</TableCell>
                                    <TableCell>{t("role")}</TableCell>
                                    <TableCell>{t("email")}</TableCell>
                                    <TableCell>{t("member.currentTier")}</TableCell>
                                    <TableCell align="center">{t("member.gCoin")}</TableCell>
                                    <TableCell align="center">{t("spending")}</TableCell>
                                    <TableCell align="center">{t("status")}</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items?.length > 0 ? (
                                    items.map((row) => (
                                        <TableRow key={row._id}>
                                            <TableCell>{row?.membershipId || ""}</TableCell>
                                            <TableCell>{row.fullName}</TableCell>
                                            <TableCell>{row.phone}</TableCell>
                                            <TableCell>{row.role}</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.currentTierName}</TableCell>
                                            <TableCell style={{ width: 160 }} align="center">
                                                {row.currentGCoins}
                                            </TableCell>
                                            <TableCell style={{ width: 160 }} align="center">
                                                {row.spending}
                                            </TableCell>
                                            <TableCell style={{ width: 160 }} align="center">
                                                <StatusLabel status={row.isEnabled}></StatusLabel>
                                            </TableCell>
                                            <TableCell
                                                style={{ width: 220 }}
                                                align="left"
                                                className={globalStyles.tableButton}
                                            >
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    color="info"
                                                    onClick={() => openDetail(row._id)}
                                                >
                                                    {t("view")}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    color={row.isEnabled ? "warning" : "secondary"}
                                                    onClick={() => changeStatusItem(row)}
                                                >
                                                    {row.isEnabled ? t("deactive") : t("active")}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            {t("message.notAnyRecord")}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        colSpan={3}
                                        count={totalItems}
                                        rowsPerPage={pagingData.limitPerPage}
                                        page={pagingData.page}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "rows per page",
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Dialog
                open={isOpenDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("member.confirmChangeStatusTitle")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("member.confirmChangeStatusDes", {
                            action: currentMember.isEnabled ? t("disable") : t("enable"),
                            name: currentMember.fullName,
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("cancel")}</Button>
                    <Button onClick={confirmChangeStatus} autoFocus>
                        {t("continuous")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isShowLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default withRouter(Member);
