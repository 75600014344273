import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField, Select, OutlinedInput } from "@material-ui/core";
import {
  Button,
  Backdrop,
  CircularProgress,
  Alert,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import useGlobalStyles from "../../styles";
import {
  updateBrand,
  createBrand,
  getBrandGroups,
  getBrand,
} from "../../utils/api";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  convertDataToViewMultilanguage,
  convertViewToFormData,
  setDefaultObject,
  showErrorToast,
  validateLanguageObject,
} from "../../utils/helpers";
import MultiLanguageTabs from "../../components/Common/MultiLanguageTabs";
import ImageUploader from "../../components/Common/ImageUploader";
import CKEditor from "ckeditor4-react";
function BrandForm(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation("common");
  const keys = ["name", "caption", "description"];
  const dataKeys = [
    "code",
    "name",
    "caption",
    "description",
    "groupId",
    "social_contacts_fb",
    "social_contacts_ins",
    "social_contacts_website",
    "policy",
    "policyHtml",
    "comingSoon",
    "isActive",
    "screenOrder",
  ];
  const filekeys = ["logo", "banner"];
  const dataFormkeys = [...dataKeys, ...["logoUrl", "bannerUrl"]];
  const keyLangauge = "brandLang";

  const [item, setItem] = useState({});

  const [groups, setGroups] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    if (id) {
      const itemData = await getBrand(id);
      if (itemData) {
        itemData.isActive = itemData.isActive ?? false;
        itemData.policy = itemData?.reward?.policy;
        itemData.policyHtml = itemData?.reward?.policyHtml
          ? itemData?.reward?.policyHtml
          : itemData?.reward?.policy ?? "";
        delete itemData.reward;
        if (itemData.socialContacts) {
          const fb_data = itemData.socialContacts.filter(
            (item) => item.type === "facebook"
          );
          itemData.social_contacts_fb = fb_data?.[0]?.link ?? "";
          const ins_data = itemData.socialContacts.filter(
            (item) => item.type === "instagram"
          );
          itemData.social_contacts_ins = ins_data?.[0]?.link ?? "";
          const website_data = itemData.socialContacts.filter(
            (item) => item.type === "website"
          );
          itemData.social_contacts_website = website_data?.[0]?.link ?? "";
        } else {
          itemData.social_contacts_fb = "";
          itemData.social_contacts_ins = "";
          itemData.social_contacts_website = "";
        }
        const formatDataLanguage = convertDataToViewMultilanguage(
          itemData,
          keys,
          keyLangauge
        );
        setItem(formatDataLanguage);
      }
    } else {
      const defaultData = setDefaultObject(dataFormkeys, keys, keyLangauge);
      setItem(defaultData);
    }

    setIsShowLoading(false);
  };

  const fetchInitData = async () => {
    const brandGroups = await getBrandGroups({});
    if (brandGroups?.itemList) {
      setGroups(brandGroups.itemList);
    }
  };

  const updateObjectValue = (key, value) => {
    setItem((eve) => ({ ...eve, [key]: value }));
  };

  useEffect(() => {
    fetchInitData();
    fetchItem();
  }, []);

  const languageRef = useRef();

  const submitForm = async (eve) => {
    eve.preventDefault();
    const resultValidate = validateLanguageObject(item[keyLangauge], keys)
    if (resultValidate){
      languageRef?.current?.handleActiveTab(resultValidate.indexTabLang)
      showErrorToast(`${t('requiredField', {field: resultValidate.key})}`)
      return;
    }
    
    const data = convertViewToFormData(
      item,
      keys,
      keyLangauge,
      dataKeys,
      filekeys
    );
    let result = {};
    if (id) {
      result = await updateBrand(id, data);
    } else {
      result = await createBrand(data);
    }
    if (result.status) {
      history.push("/brand");
    } else {
      setErrorMessage(result?.data?.message || "");
    }
  }

  return (
    <>
      <PageTitle
        title={t("brand.form")}
        button={
          <Button
            variant="contained"
            size="medium"
            color="inherit"
            onClick={() => history.push("/brand")}
          >
            {t("backToList")}
          </Button>
        }
      />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        {errorMessage !== "" ? (
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        ) : (
          <div></div>
        )}
        <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
          <Grid item xs={6}>
            <TextField
              required
              InputLabelProps={{ shrink: !!item.code }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.code}
              onChange={(e) => {
                updateObjectValue("code", e.target.value);
              }}
              label={t("code")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <label>{t('brandGroup.title')}</label>
            <Select
              displayEmpty
              required
              value={item.groupId || ""}
              onChange={(e) => updateObjectValue("groupId", e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              <MenuItem value="">
                {t("selectOne")} {t("brandGroup.title")}
              </MenuItem>
              {groups.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <ImageUploader
              value={item.logoUrl}
              updateObjectValue={(data) => updateObjectValue("logo", data)}
            />
          </Grid>
          <Grid item xs={6}>
            <ImageUploader
              value={item.bannerUrl}
              updateObjectValue={(data) => updateObjectValue("banner", data)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              InputLabelProps={{ shrink: !!item.social_contacts_fb }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.social_contacts_fb}
              onChange={(e) => {
                updateObjectValue("social_contacts_fb", e.target.value);
              }}
              label={t("social_contacts_fb")}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              InputLabelProps={{ shrink: !!item.social_contacts_ins }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.social_contacts_ins}
              onChange={(e) => {
                updateObjectValue("social_contacts_ins", e.target.value);
              }}
              label={t("social_contacts_ins")}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              InputLabelProps={{ shrink: !!item.social_contacts_website }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.social_contacts_website}
              onChange={(e) => {
                updateObjectValue("social_contacts_website", e.target.value);
              }}
              label={t("social_contacts_website")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {item[keyLangauge] ? (
              <MultiLanguageTabs
                ref={languageRef}
                data={item[keyLangauge]}
                updateObjectValue={(data) =>
                  updateObjectValue(keyLangauge, data)
                }
                keys={keys}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12}>
            <CKEditor
              data={item.policyHtml}
              onChange={({ editor }) => {
                updateObjectValue("policyHtml", editor.getData());
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.isActive || false}
                  onChange={(e) => {
                    updateObjectValue("isActive", e.target.checked);
                  }}
                />
              }
              label={t("IsActive")}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.comingSoon || false}
                  onChange={(e) => {
                    updateObjectValue("comingSoon", e.target.checked);
                  }}
                />
              }
              label={t("IsCommingSoon")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              InputLabelProps={{ shrink: !!item.screenOrder }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.screenOrder || ""}
              onChange={(e) => {
                updateObjectValue("screenOrder", e.target.value);
              }}
              label={t("screenOrder")}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ marginRight: "1rem" }}
                type="submit"
              >
                {t("save")}
              </Button>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => history.push("/brand")}
              >
                {t("cancel")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </>
  );
}

export default withRouter(BrandForm);
