import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
// import Dashboard from "../../pages/dashboard";
import Member from "../../pages/member";
import MemberDetail from "../../pages/member/MemberDetail";
import MemberTransaction from "../../pages/member/MemberTransaction";
import MemberTransactionDetail from "../../pages/member/MemberTransactionDetail";
import MemberTransactionForm from "../../pages/member/MemberTransactionForm";
import MemberCoupon from "../../pages/member/MemberCoupon";
import MemberCouponForm from "../../pages/member/MemberCouponForm";
import MemberCouponDetail from "../../pages/member/MemberCouponDetail";
import BrandGroup from "../../pages/brandGroup/BrandGroup";
import BrandGroupDetail from "../../pages/brandGroup/BrandGroupDetail";
import BrandGroupForm from "../../pages/brandGroup/BrandGroupForm";
import Coupon from "../../pages/coupon/Coupon";
import CouponDetail from "../../pages/coupon/CouponDetail";
import CouponForm from "../../pages/coupon/CouponForm";

import Brand from "../../pages/brand/Brand";
import BrandDetail from "../../pages/brand/BrandDetail";
import BrandForm from "../../pages/brand/BrandForm";

import BrandHotItem from "../../pages/brandHotItem/BrandHotItem";
import BrandHotItemDetail from "../../pages/brandHotItem/BrandHotItemDetail";
import BrandHotItemForm from "../../pages/brandHotItem/BrandHotItemForm";

import BrandLocation from "../../pages/brandLocation/BrandLocation";
import BrandLocationDetail from "../../pages/brandLocation/BrandLocationDetail";
import BrandLocationForm from "../../pages/brandLocation/BrandLocationForm";

import BrandNews from "../../pages/brandNews/BrandNews";
import BrandNewsDetail from "../../pages/brandNews/BrandNewsDetail";
import BrandNewsForm from "../../pages/brandNews/BrandNewsForm";

import BrandAds from "../../pages/brandAds/BrandAds";
import BrandAdsDetail from "../../pages/brandAds/BrandAdsDetail";
import BrandAdsForm from "../../pages/brandAds/BrandAdsForm";

import PointScheme from "../../pages/pointScheme/PointScheme";
import PointSchemeDetail from "../../pages/pointScheme/PointSchemeDetail";
import PointSchemeForm from "../../pages/pointScheme/PointSchemeForm";

import Push from "../../pages/push/Push";
import PushDetail from "../../pages/push/PushDetail";
import PushForm from "../../pages/push/PushForm";

import Staff from "../../pages/staff/Staff";
import StaffDetail from "../../pages/staff/StaffDetail";
import StaffForm from "../../pages/staff/StaffForm";

import Partner from "../../pages/partner/Partner";
import PartnerDetail from "../../pages/partner/PartnerDetail";
import PartnerForm from "../../pages/partner/PartnerForm";

import Report from "../../pages/report/Report"
import ReportPush from "../../pages/report/ReportPush"
import Admin from "../../pages/admin/Admin";
import AdminDetail from "../../pages/admin/AdminDetail";
import AdminForm from "../../pages/admin/AdminForm";

import OldMember from "../../pages/oldMember/OldMember";
import OldMemberDetail from "../../pages/oldMember/OldMemberDetail";

import Tier from "../../pages/tier";
import TierForm from "../../pages/tier/TierForm";

import PolicyForm from "../../pages/setting/PolicyForm";

// context
import { useLayoutState } from "../../context/LayoutContext";
import { ToastContainer } from 'react-toastify';

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />
              {/* Same as */}
            <ToastContainer />
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/dashboard" component={Report} />

              <Route path="/member/:id" component={MemberDetail} />
              <Route path="/member" component={Member} />
              <Route path="/member-transaction/add-point" component={MemberTransactionForm} />
              <Route path="/member-transaction/:id" component={MemberTransactionDetail} />
              <Route path="/member-transaction" component={MemberTransaction} />
              <Route path="/member-coupon/add" component={MemberCouponForm} />
              <Route path="/member-coupon/:id" component={MemberCouponDetail} />
              <Route path="/member-coupon" component={MemberCoupon} />

              <Route path="/brand-group/:id/edit" component={BrandGroupForm} />
              <Route path="/brand-group/add" component={BrandGroupForm} />
              <Route path="/brand-group/:id" component={BrandGroupDetail} />
              <Route path="/brand-group" component={BrandGroup} />

              <Route path="/brand/:id/edit" component={BrandForm} />
              <Route path="/brand/add" component={BrandForm} />
              <Route path="/brand/:id" component={BrandDetail} />
              <Route path="/brand" component={Brand} />

              <Route path="/coupon/:id/edit" component={CouponForm} />
              <Route path="/coupon/add" component={CouponForm} />
              <Route path="/coupon/:id" component={CouponDetail} />
              <Route path="/coupon" component={Coupon} />

              <Route path="/news/:id/edit" component={BrandNewsForm} />
              <Route path="/news/add" component={BrandNewsForm} />
              <Route path="/news/:id" component={BrandNewsDetail} />
              <Route path="/news" component={BrandNews} />

              <Route path="/brand-item/:id/edit" component={BrandHotItemForm} />
              <Route path="/brand-item/add" component={BrandHotItemForm} />
              <Route path="/brand-item/:id" component={BrandHotItemDetail} />
              <Route path="/brand-item" component={BrandHotItem} />

              <Route path="/brand-location/:id/edit" component={BrandLocationForm} />
              <Route path="/brand-location/add" component={BrandLocationForm} />
              <Route path="/brand-location/:id" component={BrandLocationDetail} />
              <Route path="/brand-location" component={BrandLocation} />

              <Route path="/brand-ads/:id/edit" component={BrandAdsForm} />
              <Route path="/brand-ads/add" component={BrandAdsForm} />
              <Route path="/brand-ads/:id" component={BrandAdsDetail} />
              <Route path="/brand-ads" component={BrandAds} />

              <Route path="/point-scheme/:id/edit" component={PointSchemeForm} />
              <Route path="/point-scheme/add" component={PointSchemeForm} />
              <Route path="/point-scheme/:id" component={PointSchemeDetail} />
              <Route path="/point-scheme" component={PointScheme} />

              {/* <Route path="/push/:id/edit" component={PushForm} /> */}
              <Route path="/push/add" component={PushForm} />
              <Route path="/push/:id" component={PushDetail} />
              <Route path="/push" component={Push} />

              <Route path="/staff/:id/edit" component={StaffForm} />
              <Route path="/staff/add" component={StaffForm} />
              <Route path="/staff/:id" component={StaffDetail} />
              <Route path="/staff" component={Staff} />

              <Route path="/partner/:id/edit" component={PartnerForm} />
              <Route path="/partner/add" component={PartnerForm} />
              <Route path="/partner/:id" component={PartnerDetail} />
              <Route path="/partner" component={Partner} />

              <Route path="/report" component={ReportPush} />

              <Route path="/policy" component={PolicyForm} />

              <Route path="/tier/:id/edit" component={TierForm} />
              <Route path="/tier" component={Tier} />

              <Route path="/admin/:id/edit" component={AdminForm} />
              <Route path="/admin/add" component={AdminForm} />
              <Route path="/admin/:id" component={AdminDetail} />
              <Route path="/admin" component={Admin} />

              <Route path="/old-member/:id" component={OldMemberDetail} />
              <Route path="/old-member" component={OldMember} />
            </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
