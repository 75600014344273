import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
} from "@material-ui/core";
import { Button, Backdrop, CircularProgress, Alert } from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import useGlobalStyles from "../../styles";
import { getPolicy, updatePolicy } from "../../utils/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { convertDataToViewMultilanguage, convertViewToFormData, validateLanguageObject } from "../../utils/helpers";
import MultiLanguageTabs from "../../components/Common/MultiLanguageTabs";

function PolicyForm(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { t } = useTranslation('common');
  const keys = ['termsOfUseHtml', 'privacyPolicyHtml', 'aboutUsHtml', 'termsOfUse', 'privacyPolicy', 'aboutUs', 'contactUs'];
  const showkeys = ['termsOfUseHtml', 'privacyPolicyHtml', 'aboutUsHtml', 'contactUs'];
  const dataKeys = ['version', 'termsOfUseHtml', 'privacyPolicyHtml', 'aboutUsHtml',
    'termsOfUse', 'privacyPolicy', 'aboutUs', 'contactUs'];
  const keyLangauge = 'couponLang'


  const [item, setItem] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  

  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    const itemData = await getPolicy();
    itemData.termsOfUseHtml = itemData.termsOfUseHtml ? itemData.termsOfUseHtml : itemData.termsOfUse
    itemData.privacyPolicyHtml = itemData.privacyPolicyHtml ? itemData.privacyPolicyHtml : itemData.privacyPolicy
    itemData.aboutUsHtml = itemData.aboutUsHtml ? itemData.aboutUsHtml : itemData.aboutUs
    const formatDataLanguage = convertDataToViewMultilanguage(itemData, keys, keyLangauge)
    setItem(formatDataLanguage);
    setIsShowLoading(false);
  }

  const updateObjectValue = (key, value) => {
    setItem(eve => ({...eve, [key]: value}))
  }

  const updateObjectValueSubKey = (key, subKey, value) => {
    setItem(eve => ({...eve, [key]: {...eve[key], [subKey]: value}}))
  }

  useEffect(() => {
    fetchItem()
  },[])

  const languageRef = useRef();

  const submitForm = async (eve) => {
    eve.preventDefault();

    const resultValidate = validateLanguageObject(item[keyLangauge], keys)
    if (resultValidate){
      languageRef?.current?.handleActiveTab(resultValidate.indexTabLang)
      showErrorToast(`${t('requiredField', {field: resultValidate.key})}`)
      return;
    }
    const data = convertViewToFormData(item, keys, keyLangauge, dataKeys, [])
    let result = {}
    if (item._id) {
      result = await updatePolicy(item._id, data)
      if(result.status) {
        setSuccessMessage(t('message.itemUpdateSuccessful', {item: t('setting.policy')}))
      } else {
        setErrorMessage(result?.data?.message || '')
      }
    } else {
      setErrorMessage(t('message.canNotUpdateMissingSetting'))
    }
  }

  return (
    <>
      <PageTitle title={t('setting.policy')} />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        {errorMessage !== '' && <Grid item xs={12} style={{marginBottom: "20px"}}><Alert severity="error">{errorMessage}</Alert></Grid>}
        {successMessage !== '' && <Grid item xs={12} style={{marginBottom: "20px"}}><Alert severity="success">{successMessage}</Alert></Grid>}
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{ shrink: !!item.version }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.version}
              onChange={e => { updateObjectValue('version', e.target.value) }}
              label={t('version')}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12}>
            { 
              item[keyLangauge] ? 
              (<MultiLanguageTabs data={item[keyLangauge]} ref={languageRef} updateObjectValue={(data) => updateObjectValue(keyLangauge, data)} keys={showkeys}/>) : 
              (<></>) 
            }
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{marginBottom: "1rem"}}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" sx={{ marginRight: "1rem" }} type="submit">
                {t('save')}
              </Button>
              <Button variant="contained" color="inherit" onClick={() => history.push('/coupon')}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </>
  );
}

export default withRouter(PolicyForm);