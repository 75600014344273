import React, { useState, useEffect, useRef } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import {
  Bar,
  Pie,
  getDatasetAtEvent,
  getElementAtEvent,
} from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);
import {
  Select,
  Grid,
  OutlinedInput,
  TextField
} from "@material-ui/core";
import { MenuItem, Button } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import HightSpending from "./components/HightSpending";
import HightVisit from "./components/HightVisit";
import MemberHasTransaction from "./components/MemberHasTransaction";

import useStyles from "./styles";
import useGlobalStyles from "../../styles";
import { reportRequestConfig, DEFAULT_OPTIONS_REPORTS } from "./request.config";
import { getReport } from "../../utils/api";
import { formatDatepicker } from "../../utils/helpers";

export default function Report() {
  const globalStyles = useGlobalStyles();
  const classes = useStyles();
  const { t } = useTranslation("common");
  const [datePickerValue, setDatePickerValue] = useState({
    from: null,
    to: null,
  });

  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [indexOptions, setIndexOptions] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [pageReload, setPageReload] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const chartRef = useRef(null);

  const printDatasetAtEvent = (dataset) => {
    if (!dataset.length) return;
  };

  const printElementAtEvent = (element) => {
    if (!element.length) return;
  };

  const handleOnClick = (event) => {
    const { current } = chartRef;
    printDatasetAtEvent(getDatasetAtEvent(current, event));
    printElementAtEvent(getElementAtEvent(current, event));
  };

  const renderChart = () => {
    if (loading === true) {
      return <div>Loading...</div>;
    } else {
      switch (indexOptions) {
        case 1:
          if (Object.keys(chartData).length > 0) {
            return (
              <div
                className={`${classes.chartWrapper} ${classes.pieChartWrapper}`}
              >
                <Pie ref={chartRef} data={chartData} onClick={handleOnClick} />
              </div>
            );
          }
          return;
        case 2:
          if (Object.keys(chartData).length > 0) {
            return (
              <div
                className={`${classes.chartWrapper} ${classes.pieChartWrapper}`}
              >
                <Bar ref={chartRef} data={chartData} onClick={handleOnClick} />
              </div>
            );
          }
          return;
        case 3:
          return (
            <div className={`${classes.chartWrapper}`}>
              <HightSpending
                tableData={tableData}
                updatePage={(data) => setPage(data)}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </div>
          );
        case 4:
          return (
            <div className={`${classes.chartWrapper}`}>
              <HightVisit
                tableData={tableData}
                updatePage={(data) => setPage(data)}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </div>
          );
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
          return (
            <div className={`${classes.chartWrapper}`}>
              <MemberHasTransaction
                tableData={tableData}
                updatePage={(data) => setPageReload(data)}
                total={totalItems}
                page={pageReload}
                rowsPerPage={rowsPerPage}
              />
            </div>
          );
        default:
          return <div></div>;
      }
    }
  };

  async function fetchReportData() {
    const url = reportRequestConfig[indexOptions];
    const payload = {
      page: pageReload,
      limitPerPage: rowsPerPage,
    }

    if (datePickerValue.from && datePickerValue.from !== '') {
      payload.startDate = datePickerValue.from
    }
    if (datePickerValue.to && datePickerValue.to !== '') {
      payload.endDate = datePickerValue.to
    }

    const result = await getReport(url, payload);
    if (result.status) {
      switch(indexOptions){
        case 1:
        case 2:
          setChartData(result.data);
          setIsError(false);
          break
        case 3:
        case 4:
          setTableData(result.data);
          setIsError(false);
          break
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
          setTableData(result.data.itemList);
          setTotalItems(result.data.totalItems);
          setIsError(false);
          break
      }
    } else {
      setIsError(true);

    }
    setLoading(false);
  }

  useEffect(() => {
    fetchReportData();
    return () => {};
  }, [indexOptions, pageReload]);

  const changeReport = (index) => {
    setPage(0);
    setPageReload(1);
    setDatePickerValue({
      from: null,
      to: null,
    })
    setIndexOptions(Number(index));
  }

  return (
    <>
      <div>
        <PageTitle title={t("report.title")} />
      </div>
      <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
        <Grid item xs={4}>
          <label>{t('report.title')}</label>
          <Select
            displayEmpty
            value={indexOptions}
            input={
              <OutlinedInput
                labelWidth={0}
                classes={{
                  notchedOutline: globalStyles.mainChartSelectRoot,
                  input: globalStyles.mainChartSelect,
                }}
              />
            }
            fullWidth
          >
            {Object.entries(DEFAULT_OPTIONS_REPORTS).map((item, index) => (
              <MenuItem
                value={item[0]}
                onClick={() => changeReport(item[0])}
                key={index}
              >
                {item[1]}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            label={t('startDate')}
            value={datePickerValue.from}
            views={["year", "month", "day"]}
            onChange={(e) => setDatePickerValue({
              ...datePickerValue,
              from: formatDatepicker(e),
            })}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                inputProps={{
                  ...params.inputsProps,
                  value: datePickerValue.from ?? '',
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            label={t('endDate')}
            value={datePickerValue.to}
            views={["year", "month", "day"]}
            onChange={(e) => setDatePickerValue({
              ...datePickerValue,
              to: formatDatepicker(e),
            })}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                inputProps={{
                  ...params.inputsProps,
                  value: datePickerValue.to ?? '',
                  placeholder: t("endDate"),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            size="medium"
            color="inherit"
            onClick={fetchReportData}
          >
            { t('search') }
          </Button>
        </Grid>
      </Grid>
      {isError === true ? <div>Something wrong...</div> : renderChart()}
    </>
  );
}
