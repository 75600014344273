import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
  },
  pageSubTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(5),
  },
  typo: {
    color: theme.palette.text.hint,
  },
  textFieldUnderline: {
      "&:before": {
          borderBottomColor: theme.palette.primary.light,
      },
      "&:after": {
          borderBottomColor: theme.palette.primary.main,
      },
      "&:hover:before": {
          borderBottomColor: `${theme.palette.primary.light} !important`,
      },
  },
  textField: {
      borderBottomColor: theme.palette.background.light,
  },
  fullWidth: {
      width: "100%",
  },
  containerDetail: {
      backgroundColor: '#fff',
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    paddingRight: 25,
    display: 'flex',
    alignItems: 'center',
  },
  tableButton: {
    "&>button": {
      marginRight: "1rem",
      marginBottom: "0.5rem",
      "&:last-child": {
        marginRight: "0px"
      }
    }
  },
  coverRadioItem: {
    margin: '0.25rem 0rem',
    padding: '0.25rem 1rem',
    border: '1px solid #333',
    borderRadius: '4px'
  },
  coverRadioSubItemContainer: {
    paddingLeft: '2.5rem'
  }
}));
