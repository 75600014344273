import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel, { a11yProps } from "./TabPanel";
import { MULTIPLE_LANGUAGES } from "../../utils/constant";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@material-ui/core";
import useGlobalStyles from "../../styles";
import PropTypes from "prop-types";
import CKEditor from "ckeditor4-react";

const MultiLanguageTabs = forwardRef((props, ref) => {
  const { t } = useTranslation("common");
  const [activeTab, setActiveTab] = useState(0);
  const [languages, setLanguages] = useState([]);
  const globalStyles = useGlobalStyles();
  const textareaKeys = ["description"];
  const ckeditorKeys = [
    "contentHtml",
    "termsOfUseHtml",
    "privacyPolicyHtml",
    "aboutUsHtml",
  ];

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  useImperativeHandle(ref, () => ({
    handleActiveTab(indexTab) {
      setActiveTab(indexTab)
    }
  }));

  useEffect(() => {
    setLanguages(props.data);
  }, [props]);

  const updateSubObjectValue = (keyLang, key, value) => {
    const newValue = {
      ...languages,
      [keyLang]: {
        ...languages[keyLang],
        [key]: value,
      },
      [key]: {
        ...languages[key],
        [keyLang]: value,
      }
    };
    setLanguages(newValue);
    props.updateObjectValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", border: 1, borderColor: "divider" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          {MULTIPLE_LANGUAGES.map((item, key) => (
            <Tab key={key} label={t(item)} {...a11yProps(key)} />
          ))}
        </Tabs>
      </Box>
      {MULTIPLE_LANGUAGES.map((item, key) => (
        <TabPanel value={activeTab} index={key} key={key}>
          <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
            <Grid item xs={12}>
              {props.keys.map((keyObj) =>
                textareaKeys.includes(keyObj) ? (
                  <TextField
                    required
                    style={{ marginTop: "15px", marginBottom: "5px" }}
                    key={keyObj}
                    InputLabelProps={{ shrink: !!languages?.[item]?.[keyObj] }}
                    InputProps={{
                      classes: {
                        underline: globalStyles.textFieldUnderline,
                        input: globalStyles.textField,
                      },
                    }}
                    value={languages?.[item]?.[keyObj] || ""}
                    onChange={(e) => {
                      updateSubObjectValue(item, keyObj, e.target.value);
                    }}
                    label={t(keyObj)}
                    fullWidth
                    multiline={true}
                    minRows={5}
                  />
                ) : ckeditorKeys.includes(keyObj) ? (
                  <div key={keyObj}>
                    <h5>{t(keyObj)}</h5>
                    <CKEditor
                      data={languages?.[item]?.[keyObj] || ""}
                      onChange={({ editor }) => {
                        updateSubObjectValue(item, keyObj, editor.getData());
                      }}
                    />
                  </div>
                ) : (
                  <TextField
                    required
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                    key={keyObj}
                    InputLabelProps={{ shrink: !!languages?.[item]?.[keyObj] }}
                    InputProps={{
                      classes: {
                        underline: globalStyles.textFieldUnderline,
                        input: globalStyles.textField,
                      },
                    }}
                    value={languages?.[item]?.[keyObj] || ""}
                    onChange={(e) => {
                      updateSubObjectValue(item, keyObj, e.target.value);
                    }}
                    label={t(keyObj)}
                    fullWidth
                  />
                )
              )}
            </Grid>
          </Grid>
        </TabPanel>
      ))}
    </Box>
  );
});

MultiLanguageTabs.propTypes = {
  data: PropTypes.object.isRequired,
  keys: PropTypes.array.isRequired,
  updateObjectValue: PropTypes.func.isRequired,
};

export default MultiLanguageTabs;
