import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField, Select, OutlinedInput } from "@material-ui/core";
import {
  Button,
  Backdrop,
  CircularProgress,
  Alert,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
// styles
import useGlobalStyles from "../../styles";
import {
  getBrands,
  getPaymentMethods,
  getBrandLocation,
  updateBrandLocation,
  createBrandLocation,
} from "../../utils/api";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  convertDataToViewMultilanguage,
  convertViewToFormData,
  setDefaultObject,
  validateLanguageObject,
} from "../../utils/helpers";
import MultiLanguageTabs from "../../components/Common/MultiLanguageTabs";
import ImageUploader from "../../components/Common/ImageUploader";
import { emailValidator } from "../../utils/helpers"

function BrandLocationForm(props) {
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation("common");
  const keys = ["name", "address", "openingTime"];
  const dataKeys = [
    "code",
    "name",
    "address",
    "openingTime",
    "brandId",
    "isActive",
  ];
  const arrayKey = ["paymentMethods"];
  const arrayObject = ["contacts", "coordinates"];
  const filekeys = ["mainImage"];
  const dataFormkeys = [...dataKeys, ...["mainImageUrl"]];
  const keyLangauge = "locationLang";

  const [item, setItem] = useState([]);
  const [groups, setGroups] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationError , setValidationError] = useState({
    email: false,
    phone: false
  })

  const [isShowLoading, setIsShowLoading] = useState(false);

  const fetchItem = async () => {
    setIsShowLoading(true);
    if (id) {
      const itemData = await getBrandLocation(id);
      itemData.mainImageUrl = itemData.mainImage;
      delete itemData.mainImage;
      itemData.brandId = itemData.brand?._id;
      delete itemData.brand;
      const formatDataLanguage = convertDataToViewMultilanguage(
        itemData,
        keys,
        keyLangauge
      );
      setItem(formatDataLanguage);
    } else {
      const defaultData = setDefaultObject(dataFormkeys, keys, keyLangauge);
      setItem(defaultData);
    }
    setIsShowLoading(false);
  };

  const updateObjectValue = (key, value) => {
    setItem((eve) => ({ ...eve, [key]: value }));
  };

  const updateObjectValueSubKey = (key, subKey, value) => {
    setItem((eve) => ({ ...eve, [key]: { ...eve[key], [subKey]: value } }));
  };

  const fetchInitData = async () => {
    const brands = await getBrands({isActive: true});
    if (brands?.itemList) {
      setGroups(brands.itemList);
    }
    const payMethods = await getPaymentMethods();
    if (payMethods) {
      setPaymentMethods(payMethods);
    }
  };

  useEffect(() => {
    fetchItem();
    fetchInitData();
  }, []);

  const languageRef = useRef();
  
  const submitForm = async (eve) => {
    eve.preventDefault();

    const resultValidate = validateLanguageObject(item[keyLangauge], keys)
    if (resultValidate){
      languageRef?.current?.handleActiveTab(resultValidate.indexTabLang)
      return;
    }

    let { email } = item?.contacts || {}
    if(email && !emailValidator(email)){
      setValidationError({...validationError, email: true});
    }
    const data = convertViewToFormData(
      item,
      keys,
      keyLangauge,
      dataKeys,
      filekeys,
      arrayKey,
      arrayObject
    );
    let result = {};
    if (id) {
      result = await updateBrandLocation(id, data);
    } else {
      result = await createBrandLocation(data);
    }
    if (result.status) {
      history.push("/brand-location");
    } else {
      setErrorMessage(result?.data?.message || "");
    }
  };

  return (
    <>
      <PageTitle
        title={t("brandLocation.form")}
        button={
          <Button
            variant="contained"
            size="medium"
            color="inherit"
            onClick={() => history.push("/brand-location")}
          >
            {t("backToList")}
          </Button>
        }
      />
      <form className={globalStyles.fullWidth} onSubmit={submitForm}>
        {errorMessage !== "" ? (
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        ) : (
          <div></div>
        )}
        <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
          <Grid item xs={6}>
            <label>{t('brand.title')}</label>
            <Select
              required
              displayEmpty
              value={item.brandId || ""}
              onChange={(e) => updateObjectValue("brandId", e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              <MenuItem value="">
                {t("selectOne")} {t("brand.title")}
              </MenuItem>
              {groups.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              InputLabelProps={{ shrink: !!item.code }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.code}
              onChange={(e) => {
                updateObjectValue("code", e.target.value);
              }}
              label={t("code")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <label>{t('paymentMethods.title')}</label>
            <Select
              required
              displayEmpty
              multiple
              value={item.paymentMethods || []}
              onChange={(e) =>
                updateObjectValue("paymentMethods", e.target.value)
              }
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: globalStyles.mainChartSelectRoot,
                    input: globalStyles.mainChartSelect,
                  }}
                />
              }
              fullWidth
            >
              {paymentMethods.map((item) => (
                <MenuItem key={item} value={item}>
                  {t(`paymentMethods.${item}`)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <ImageUploader
              value={item.mainImageUrl}
              updateObjectValue={(data) => updateObjectValue("mainImage", data)}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              error={validationError.email}
              InputLabelProps={{ shrink: !!item.contacts?.email }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.contacts?.email || ""}
              onChange={(e) => {
                updateObjectValueSubKey("contacts", "email", e.target.value);
              }}
              label={t("email")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              InputLabelProps={{ shrink: !!item.contacts?.phone }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.contacts?.phone || ""}
              onChange={(e) => {
                updateObjectValueSubKey("contacts", "phone", e.target.value);
              }}
              label={t("phone")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              InputLabelProps={{ shrink: !!item.coordinates?.latitude }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.coordinates?.latitude || ""}
              onChange={(e) => {
                updateObjectValueSubKey(
                  "coordinates",
                  "latitude",
                  e.target.value
                );
              }}
              label={t("latitude")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              InputLabelProps={{ shrink: !!item.coordinates?.longitude }}
              InputProps={{
                classes: {
                  underline: globalStyles.textFieldUnderline,
                  input: globalStyles.textField,
                },
              }}
              value={item.coordinates?.longitude || ""}
              onChange={(e) => {
                updateObjectValueSubKey(
                  "coordinates",
                  "longitude",
                  e.target.value
                );
              }}
              label={t("longitude")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {item[keyLangauge] ? (
              <MultiLanguageTabs
                ref={languageRef}
                data={item[keyLangauge]}
                updateObjectValue={(data) =>
                  updateObjectValue(keyLangauge, data)
                }
                keys={keys}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.isActive || false}
                  onChange={(e) => {
                    updateObjectValue("isActive", e.target.checked);
                  }}
                />
              }
              label={t("IsActive")}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ marginRight: "1rem" }}
                type="submit"
              >
                {t("save")}
              </Button>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => history.push("/brand-location")}
              >
                {t("cancel")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </>
  );
}

export default withRouter(BrandLocationForm);
